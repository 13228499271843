<!--Simple toast-->
<div
  class="d-flex justify-content-between toaster"
  *ngIf="!data.component"
>
  <div class="d-flex">
    <div class="icon {{ data.type }}">
      <ng-container [ngSwitch]="data.type">
        <ng-container *ngSwitchCase="toastType.SUCCESS">✓</ng-container>
        <ng-container *ngSwitchCase="toastType.ERROR">!</ng-container>
        <ng-container *ngSwitchDefault>i</ng-container>
      </ng-container>
    </div>
    <div class="align-items-center">
      <div class="ellipsis fw-semi-bold fs-14">
        {{ data.title }}
      </div>
      <div
        *ngIf="data.content?.message"
        class="ellipsis content fs-12"
      >
        {{ data.content.message }}
      </div>
    </div>
  </div>
  <div>
    <ng-container *ngTemplateOutlet="closeButton"></ng-container>
  </div>
</div>

<ng-template #closeButton>
  <a
    class="close-button d-flex pointer"
    (click)="$event.preventDefault(); closeToast()"
  >
    <mat-icon class="material-icons">close</mat-icon>
  </a>
</ng-template>
