export enum PermissionsEnum {
  isAr = 'isAr',
  isScheduleUser = 'isScheduleUser',
  menuBooking = 'menuBooking',
  menuSchedule = 'menuSchedule',
  menuSessionSheets = 'menuSessionSheets',
  menuInvoices = 'menuInvoices',
  menuInvoicesTemp = 'menuInvoicesTemp',
  menuPurchaseOrders = 'menuPurchaseOrders',
  menuContacts = 'menuContacts',
  menuSettings = 'menuSettings',
  menuReports = 'menuReports',
  settingsTabStudioSite = 'settingsTabStudioSite',
  settingsTabStudioSiteMiniConsoleStudio = 'settingsTabStudioSiteMiniConsoleStudio',
  settingsTabInvoicingNotRequired = 'settingsTabInvoicingNotRequired',
  settingsTabCmsAccessOnly = 'settingsTabCmsAccessOnly',
  settingsTabBookings = 'settingsTabBookings',
  settingsTabUsers = 'settingsTabUsers',
  settingsTabEngineers = 'settingsTabEngineers',
  settingsTabUsersScUsers = 'settingsTabUsersScUsers',
  settingsTabUsersArUsers = 'settingsTabUsersArUsers',
  settingsTabUsersUserGroups = 'settingsTabUsersUserGroups',
  settingsTabSessionSheets = 'settingsTabSessionSheets',
  settingsTabAccounting = 'settingsTabAccounting',
  settingsTabInvoicing = 'settingsTabInvoicing',
  settingsTabNotifications = 'settingsTabNotifications',
  settingsTabInvoicingThirdPartyBilling = 'settingsTabInvoicingThirdPartyBilling',
  settingsTabSchedule = 'settingsTabSchedule',
  settingsTabStudios = 'settingsTabStudios',
  settingsTabCreateStudio = 'settingsTabCreateStudio',
  settingsTabThirdPartyStudio = 'settingsTabThirdPartyStudio',
  settingsTabWebsiteAndDirectory = 'settingsTabWebsiteAndDirectory',
  bookings = 'bookings',
  bookingView = 'bookingView',
  schedule = 'schedule',
  scheduleCreateBooking = 'scheduleCreateBooking',
  scheduleCreateNonBooking = 'scheduleCreateNonBooking',
  scheduleCreateMeeting = 'scheduleCreateMeeting',
  scheduleGeneralCalendarView = 'scheduleGeneralCalendarView',
  sessionSheets = 'sessionSheets',
  contacts = 'contacts',
  contactsAddressBook = 'contactsAddressBook',
  contactsSonyContacts = 'contactsSonyContacts',
  contactsSonyCompanies = 'contactsSonyCompanies',
  settingsUsersScheduleUsersAddNew = 'settingsUsersScheduleUsersAddNew',
  settingsUsersScheduleUsersDefaultsViewOnly = 'settingsUsersScheduleUsersDefaultsViewOnly',
  scheduleAllowCancelledEvents = 'scheduleAllowCancelledEvents',
  bookingsDeletedTab = 'bookingsDeletedTab',
  isArBooker = 'isArBooker',
}
