import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppInputComponent } from './app-input.component';

@NgModule({
  declarations: [AppInputComponent],
  imports: [CommonModule],
  exports: [AppInputComponent],
})
export class AppInputModule {}
