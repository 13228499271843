import { PermissionsEnum, RoutesEnum } from '@shared/enums';
import { NavItem } from '@shared/modules/components/app-sidenav/models/nav-item.interface';

export const navItems: NavItem[] = [
  {
    displayName: 'BOOKINGS.DASHBOARD',
    iconName: 'studios',
    route: RoutesEnum.homeDirectory,
    disabled: false,
    isHomeMenu: true,
  },
  {
    displayName: 'SHARED_MESSAGES.SETTINGS',
    iconName: 'settings',
    route: RoutesEnum.homeSettings,
    disabled: false,
    isHomeMenu: true,
  },
  {
    displayName: 'BOOKINGS.BOOKINGS',
    iconName: 'bookings',
    route: RoutesEnum.bookings,
    disabled: false,
    permissionName: PermissionsEnum.menuBooking,
  },
  {
    displayName: 'BOOKINGS.SCHEDULE',
    iconName: 'schedule',
    route: RoutesEnum.schedule,
    disabled: false,
    permissionName: PermissionsEnum.menuSchedule,
  },
  {
    displayName: 'BOOKINGS.SESSION_SHEETS',
    iconName: 'session-sheets',
    route: RoutesEnum.sessionSheets,
    disabled: false,
    permissionName: PermissionsEnum.menuSessionSheets,
  },
  {
    displayName: 'BOOKINGS.INVOICES',
    iconName: 'invoices',
    route: RoutesEnum.invoices,
    disabled: false,
    permissionName: PermissionsEnum.menuInvoices,
  },
  // {
  //   displayName: 'BOOKINGS.INVOICES_TEMP',
  //   iconName: 'invoices',
  //   route: RoutesEnum.invoicesTemp,
  //   disabled: false,
  //   permissionName: PermissionsEnum.menuInvoicesTemp,
  // },
  {
    displayName: 'BOOKINGS.PURCHASE_ORDERS',
    iconName: 'purchase-orders',
    route: RoutesEnum.purchaseOrders,
    disabled: false,
    permissionName: PermissionsEnum.menuPurchaseOrders,
  },
  {
    displayName: 'BOOKINGS.CONTACTS',
    iconName: 'contacts',
    route: RoutesEnum.contacts,
    disabled: false,
    permissionName: PermissionsEnum.menuContacts,
  },
  {
    displayName: 'SHARED_MESSAGES.REPORTS',
    iconName: 'reports',
    route: RoutesEnum.reports,
    disabled: false,
    permissionName: PermissionsEnum.menuReports,
  },
  {
    displayName: 'SHARED_MESSAGES.SETTINGS',
    iconName: 'settings',
    route: RoutesEnum.settings,
    disabled: false,
    permissionName: PermissionsEnum.menuSettings,
  },
];
