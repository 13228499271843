import { Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { selectActiveStudioSite } from '@auth/store/auth.selectors';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Directive({
  selector: '[thirdPartyStudio]',
})
export class ThirdPartyStudioDirective implements OnInit, OnDestroy {
  private unsubscribe$: Subject<null> = new Subject<null>();

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private store: Store
  ) {}

  public ngOnInit(): void {
    this.store
      .pipe(
        select(selectActiveStudioSite),
        takeUntil(this.unsubscribe$),
        distinctUntilChanged((oldValue, newValue) => oldValue.studioSiteId === newValue.studioSiteId)
      )
      .subscribe((studioSite) => {
        if (!studioSite.thirdPartyStudio) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
