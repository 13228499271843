import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ToastType } from '@shared/modules/components/app-toaster/app-toaster.component';
import { ToasterService } from '@shared/services/toaster.service';

import * as AuthActions from '../../store/auth.actions';
import { isExchangeTokenLoaded } from '../../store/auth.selectors';
import { LocalStorageEnum } from '@shared/enums/local-storage.enum';

@Component({
  template: '',
})
export class LoginProcessorComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store,
    private toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    const permanentItemsList = [
      'tableSettings',
      'returnUrl',
      'artistSwitcherState',
      LocalStorageEnum.LastActiveStudioSite,
      LocalStorageEnum.LogRecords,
    ];
    const permanentItems: { key: string; value: string }[] = [];
    permanentItemsList.forEach((key: string) => {
      const itemValue = localStorage.getItem(key);
      if (itemValue) {
        permanentItems.push({ key, value: itemValue });
      }
    });

    localStorage.clear();

    permanentItems.forEach((item: { key: string; value: string }) => {
      localStorage.setItem(item.key, item.value);
    });

    combineLatest([this.activatedRoute.queryParams, this.store.select(isExchangeTokenLoaded)])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([params, isLoaded]) => {
        if (params.user_message) {
          this.toasterService.showWithContent(
            ToastType.ERROR,
            params.app_code ? `App Code: ${params.app_code}` : '',
            params.user_message
          );
          this.store.dispatch(AuthActions.loginRedirect({ source: 'loginProcessor', message: params.user_message }));
          return;
        }

        if (params.exchange_token && !isLoaded) {
          this.exchangeToken(params.exchange_token);
        }
      });
  }

  exchangeToken(exchange_token: string): void {
    this.store.dispatch(AuthActions.exchangeToken({ exchange_token }));
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
