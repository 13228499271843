import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { AppButtonComponent } from './app-button.component';
import { AppButtonLabelTemplateDirective } from './app-button-label-template.directive';

@NgModule({
  declarations: [AppButtonComponent, AppButtonLabelTemplateDirective],
  imports: [CommonModule, MatIconModule, MatButtonModule],
  exports: [AppButtonComponent, AppButtonLabelTemplateDirective],
})
export class AppButtonModule {}
