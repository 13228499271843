import { IGlobalPreference } from '@shared/models/auth/global-preference.interface';
import { GlobalPrefsEnum } from '@shared/enums/global-prefs.enum';

export class GlobalPreference {
  public readonly [GlobalPrefsEnum.globalSettings]: boolean;
  public readonly [GlobalPrefsEnum.globalSettingsTabUsers]: boolean;
  public readonly [GlobalPrefsEnum.globalAdminUsers]: boolean;
  public readonly [GlobalPrefsEnum.globalSettingsUsersTable]: boolean;
  public readonly [GlobalPrefsEnum.globalSettingsAddUserGroup]: boolean;
  public readonly [GlobalPrefsEnum.globalSettingsTabBookingCategories]: boolean;

  constructor(raw?: IGlobalPreference) {
    if (!raw) {
      return;
    }

    this[GlobalPrefsEnum.globalSettings] = raw.global_settings || false;
    this[GlobalPrefsEnum.globalSettingsTabUsers] = raw.global_settings_tab_users || false;
    this[GlobalPrefsEnum.globalAdminUsers] = raw.global_admin_users || false;
    this[GlobalPrefsEnum.globalSettingsUsersTable] = raw.global_settings_users_table || false;
    this[GlobalPrefsEnum.globalSettingsAddUserGroup] = raw.global_settings_add_user_group || false;
    this[GlobalPrefsEnum.globalSettingsTabBookingCategories] = raw.global_settings_tab_booking_categories || false;
  }
}
