<div class="d-flex vh-100">
  <div class="row flex-grow-1">
    <div class="login-left col-12 col-lg-6 p-0 d-flex flex-column justify-content-center align-items-center">
      <div class="card">
        <ng-content select="[USMCardContent]"></ng-content>
      </div>

      <ng-content select="[afterCardContent]"></ng-content>
    </div>
    <div class="d-none d-lg-flex col-lg-6 p-0">
      <img
        class="image image-right vh-100 position-absolute"
        src="./assets/images/login-image-right-2.png"
        alt=""
      />
    </div>
  </div>
</div>
