import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialIconsService } from './material-icons.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class MaterialIconsModule {
  constructor(private icons: MaterialIconsService) {
    this.icons.registerIcons();
  }
}
