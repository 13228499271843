export enum PurchaseOrderStatusEnum {
  PO_REQUESTED = 1,
  PO_UPDATE_REQUESTED = 2,
  PO_CANCEL_REQUESTED = 3,
  PO_APPROVED = 4,
  PO_REJECTED = 5,
  PO_CANCELLED = 6,
  INVOICE_SUBMITTED = 7,
  PO_CLOSED = 8,
  INVOICE_REJECTED = 9,
  INVOICE_PAID = 10,
}
