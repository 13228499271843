import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { MatIconRegistry } from '@angular/material/icon';

@Injectable({
  providedIn: 'root',
})
export class MaterialIconsService {
  private readonly icons: string[] = [
    'arrow-select',
    'close-modal',
    'warning-v2',
    'close',
    'eye-v2',
    'sap-se',
    'ellipsis',
    'booking-invoice',
    'empty-invoice',
    'invoice',
    'sort',
    'po-plate',
    'sony-music-small',
    'sony-music-small-red',
    'sony-music-small-booking',
    'sony-music-small-booking-red',
    'sony-small-booking',
    'sony-small',
    'sony-sap',
    'sony-sap-grey',
    'plus-thin',
    'spacer',
    'phone-sap',
    'number-sap',
    'mobile-sap',
    'email-sap',
    'address-billing-sap',
    'search-thin',
    'company-without-oval',
    'envelope',
    'website',
    'phone',
    'download-pdf',
    'sony',
    'events-all',
    'only-cancelled',
    'only-normal-events',
    'contacts/delete',
    'pdf',
    'cancel-v2',
    'console',
    'big-plus',
    'event-mode-small',
    'event-mode-medium',
    'event-mode-large',
    'shape',
    'link-up',
    'linked',
    'meeting-icon',
    'show-hours',
    'show-multi-day',
    'add-token',
    'right-arrow',
    'hand',
    'cancel-gray',
    'check-round',
    'duplicate',
    'download-round',
    'trash',
    'plus-simple',
    'minus-simple',
    'edit-v2',
    'star',
    'star-yellow',
    'star-dark-grey',
    'prs',
    'grab-handle',
  ];

  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {}

  public registerIcons(): void {
    this.icons.forEach((value) => this.generate(value));
  }

  private generate(name: string): void {
    this.iconRegistry.addSvgIcon(
      name,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/' + name + '.svg')
    );
  }
}
