import { createAction, props } from '@ngrx/store';

import {
  Person,
  PersonRole,
  Country,
  AddressType,
  PersonLinkedCompany,
  IPersonLinkedCompany,
} from '@shared/modules/contacts/models';
import { ContactsStatusesEnum } from '@shared/modules/contacts/enums';
import { CompanyBillingMethodEnum } from '@shared/enums';

export const cancelLoadContactsPersonRequest = createAction('[ContactsPerson/UI] Cancel Load Contacts Person');

export const loadContactsPersonRequest = createAction(
  '[ContactsPerson/API] Load Contacts Person',
  props<{ personId: number }>()
);
export const loadContactsPersonRequestSuccess = createAction(
  '[ContactsPerson/API] Load Contacts Person Success',
  props<{ data: Person }>()
);
export const loadContactsPersonRequestFailure = createAction(
  '[ContactsPerson/API] Load Contacts Person Failure',
  props<{ error?: any }>()
);

export const createContactsPersonRequest = createAction(
  '[ContactsPerson/API] Create Contacts Person',
  props<{ person: Person }>()
);
export const createContactsPersonRequestSuccess = createAction(
  '[ContactsPerson/API] Create Contacts Person Success',
  props<{ data: Person }>()
);
export const createContactsPersonRequestFailure = createAction(
  '[ContactsPerson/API] Create Contacts Person Failure',
  props<{ error: any }>()
);

export const updateContactsPersonRequest = createAction(
  '[ContactsPerson/API] Update Contacts Person',
  props<{ person: Person; status?: ContactsStatusesEnum }>()
);
export const updateContactsPersonRequestSuccess = createAction(
  '[ContactsPerson/API] Update Contacts Person Success',
  props<{ data: Person }>()
);
export const updateContactsPersonRequestFailure = createAction(
  '[ContactsPerson/API] Update Contacts Person Failure',
  props<{ error: any }>()
);

export const deleteContactsPersonRequest = createAction(
  '[ContactsPerson/API] Delete Contacts Person',
  props<{ person: Person }>()
);
export const deleteContactsPersonRequestSuccess = createAction('[ContactsPerson/API] Delete Contacts Person Success');
export const deleteContactsPersonRequestFailure = createAction(
  '[ContactsPerson/API] Delete Contacts Person Failure',
  props<{ error: any }>()
);

export const updateActivePerson = createAction(
  '[ContactsPerson/UI] Update active person object in store',
  props<{ data: Person }>()
);
export const clearActivePerson = createAction('[ContactsPerson/UI] Clear Active Person in Store');

export const addContactsNewPersonCompany = createAction(
  '[ContactsPerson/UI] Add Company To Person',
  props<{ data: PersonLinkedCompany }>()
);

export const loadContactsStudioSiteRolesRequest = createAction(
  '[ContactsPerson/API] Load Contacts/Person Roles for specific Studio Site'
);
export const loadContactsStudioSiteRolesRequestSuccess = createAction(
  '[ContactsPerson/API] Load Contacts/Person Roles for specific Studio Site Success',
  props<{ data: PersonRole[] }>()
);
export const loadContactsStudioSiteRolesRequestFailure = createAction(
  '[ContactsPerson/API] Load Contacts/Person Roles for specific Studio Site Failure',
  props<{ error: any }>()
);

export const addContactsPersonCompanyRequest = createAction(
  '[ContactsPerson/API] Add Company To Person',
  props<{
    studioCompanyName?: string;
    studioCompanyType?: string;
    studioCompany?: number;
    roleInCompany: string;
  }>()
);
export const addContactsPersonCompanyRequestSuccess = createAction(
  '[ContactsPerson/API] Add Company To Person Success',
  props<{ data: IPersonLinkedCompany[] }>()
);
export const addContactsPersonCompanyRequestFailure = createAction(
  '[ContactsPerson/API] Add Company To Person Failure',
  props<{ error: any }>()
);

export const updateRoleInCompanyContactsPersonRequest = createAction(
  '[ContactsPerson/API] Update Contacts Person Company Role',
  props<{ studioContact: number; studioCompany: number; roleInCompany: string }>()
);
export const updateRoleInCompanyContactsPersonRequestSuccess = createAction(
  '[ContactsPerson/API] Update Contacts Person Company Role Success',
  props<{ data: IPersonLinkedCompany[] }>()
);
export const updateRoleInCompanyContactsPersonRequestFailure = createAction(
  '[ContactsPerson/API] Update Contacts Person Company Role Failure',
  props<{ error: any }>()
);

export const deleteContactsPersonCompanyRequest = createAction(
  '[ContactsPerson/API] Delete Company To Person',
  props<{ studioContact: number; studioCompany: number }>()
);
export const deleteContactsPersonCompanyRequestSuccess = createAction(
  '[ContactsPerson/API] Delete Company To Person Success',
  props<{ data: IPersonLinkedCompany[] }>()
);
export const deleteContactsPersonCompanyRequestFailure = createAction(
  '[ContactsPerson/API] Delete Company To Person Failure',
  props<{ error: any }>()
);

export const loadCompaniesRequest = createAction('[ContactsPerson/API] Load Companies for specific Studio Site');
export const loadCompaniesRequestSuccess = createAction(
  '[ContactsPerson/API] Load Companies for specific Studio Site Success',
  props<{ data: PersonLinkedCompany[] }>()
);
export const loadCompaniesRequestFailure = createAction(
  '[ContactsPerson/API] Load Companies for specific Studio Site Failure',
  props<{ error: any }>()
);

export const redirectToCompanyById = createAction(
  '[ContactsPerson/API] Redirect to company by Id',
  props<{ companyId: number | null }>()
);

// Countries List
export const loadCountriesListRequest = createAction('[ContactsPerson/API] Load Countries List');

export const loadCountriesListRequestSuccess = createAction(
  '[ContactsPerson/API] Load Countries List Success',
  props<{ data: Country[] }>()
);

export const loadCountriesListRequestFailure = createAction(
  '[ContactsPerson/API] Load Countries List Failure',
  props<{ error: any }>()
);

// Address Types List
export const loadAddressTypesRequest = createAction('[ContactsPerson/API] Load Address Types List');

export const loadAddressTypesRequestSuccess = createAction(
  '[ContactsPerson/API] Load Address Types List Success',
  props<{ data: AddressType[] }>()
);

export const loadAddressTypesRequestFailure = createAction(
  '[ContactsPerson/API] Load Address Types List Failure',
  props<{ error: any }>()
);

export const updateCompanyBillingMethod = createAction(
  '[ContactsPerson/API] Update Company Billing Method',
  props<{ studioContact: number; studioCompany: number; billingMethod: CompanyBillingMethodEnum }>()
);
export const updateCompanyBillingMethodSuccess = createAction(
  '[ContactsPerson/API] Update Company Billing Method Success',
  props<{ data: IPersonLinkedCompany[] }>()
);
export const updateCompanyBillingMethodFailure = createAction(
  '[ContactsPerson/API] Update Company Billing Method Failure',
  props<{ error: any }>()
);
