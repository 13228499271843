import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { AppReturnPreviousModalModule } from '../../components/app-return-previous-modal/app-return-previous-modal.module';

import { AppDialogComponent } from './app-dialog.component';
import { AppSelectableSubmenuModule } from '@shared/modules/components/app-selectable-submenu';
import { AppCardModule } from '@shared/modules/components/app-card/app-card.module';
import { AppButtonToggleGroupModule } from '@shared/modules/components/app-button-toggle-group/app-button-toggle-group.module';

@NgModule({
  declarations: [AppDialogComponent],
  imports: [
    CommonModule,
    AppReturnPreviousModalModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
    AppSelectableSubmenuModule,
    AppCardModule,
    AppButtonToggleGroupModule,
  ],
  exports: [AppDialogComponent],
})
export class AppDialogModule {}
