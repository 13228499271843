<a
  tabindex="-1"
  mat-list-item
  routerLinkActive
  class="menu-list-item d-flex align-items-center text-decoration-none is-navigate-grid-outside-click"
  #rla="routerLinkActive"
  [routerLink]="!item.disabled ? item.route : null"
  [class.disabled]="item.disabled"
  [ngClass]="{
    active: rla.isActive && !item.disabled,
    first: first,
    close: !isOpen,
    'light-mode': !darkMode && !isAr,
    'dark-mode': darkMode && !isAr,
    'ar-mode': isAr,
    'min-width-200': isOpen,
    'min-width-auto': !isOpen
  }"
  (click)="onItemSelected(item)"
>
  <svg
    class="menu-item-img"
    *ngIf="item.iconName"
  >
    <use [attr.xlink:href]="'./assets/icons/sidenav/sidenav-sprite.svg#' + item.iconName"></use>
  </svg>

  <span class="menu-name fw-medium">{{ item.displayName | translate }}</span>
</a>
