import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { OktaSessionService } from '@auth/services/okta-session.service';
import { AuthState } from '@auth/store/auth.reducer';
import { Title } from '@angular/platform-browser';

import { getLoggedIn, getUser, selectActiveStudioSiteLoaded } from '@auth/store/auth.selectors';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { UserModel } from '@shared/models/auth/user.model';
import { NavItem } from '@shared/modules/components/app-sidenav/models/nav-item.interface';
import { AppConfigService } from '@shared/services/app-config.service';
import { saveLastPage } from '@shared/utils/last-page-history.utils';
import { Observable, Subject } from 'rxjs';

import { filter, pairwise, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as AuthActions from '../../../auth/store/auth.actions';
import * as CoreActions from '../../../core/store/core.actions';

import { navItems } from '../../nav-items';
import { LoadingService } from '@core/service/loading.service';
import { DOCUMENT } from '@angular/common';
import { LoaderService } from '@core/service/loader.service';
import { IApiDataResponse } from '@shared/models';

@Component({
  selector: 'app-root',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('matSidenav')
  matSidenav!: MatSidenav;

  public isLoggedIn$ = this.store.pipe(select(getLoggedIn));
  public user$: Observable<UserModel | null> = this.store.pipe(select(getUser));
  public studioSiteLoaded$ = this.store.pipe(select(selectActiveStudioSiteLoaded));
  public loader$ = this.loaderService.loader$;

  public navItems: NavItem[] = navItems;
  public fullScreenLayout = false;

  private unsubscribe$ = new Subject<void>();
  private currentIsLoggedIn = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public oktaSessionService: OktaSessionService,
    private store: Store<AuthState>,
    private translateService: TranslateService,
    private router: Router,
    private appConfigService: AppConfigService,
    private titleService: Title,
    private loadingService: LoadingService,
    private loaderService: LoaderService
  ) {
    this.titleService.setTitle(this.appConfigService.pageTitle);

    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((events: RoutesRecognized[]) => {
        this.store.dispatch(CoreActions.setPreviousUrl({ url: events[0].urlAfterRedirects }));
      });
  }

  public ngOnInit(): void {
    this.loadingSubscription();
    this.translateService.use(environment.defaultLocale);
    this.routerStateSubscription();
    this.store.dispatch(AuthActions.setAuthTypeUsm({ isUsm: this.appConfigService.isUSMAuth }));

    this.user$.pipe(takeUntil(this.unsubscribe$)).subscribe((user: any) => {
      const isLoggedIn = !!user;
      if (!user) {
        return;
      }
      if (this.currentIsLoggedIn !== isLoggedIn) {
        this.currentIsLoggedIn = isLoggedIn;
        if (isLoggedIn) {
          this.oktaSessionService.start(user.lifetime * 1000);
          localStorage.setItem('startUrl', window.location.pathname);
          this.store.dispatch(AuthActions.loadStudioSites({ isSimpleRefresh: false }));
        }
      }
    });
    this.loadAppVersion();
  }

  private loadingSubscription(): void {
    this.loadingService.loading$.pipe(takeUntil(this.unsubscribe$)).subscribe((loading) => {
      const body = this.document.body.classList;
      loading ? body.remove('requests-loaded') : body.add('requests-loaded');
    });
  }

  private routerStateSubscription(): void {
    this.router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((e) => e instanceof NavigationEnd)
      )
      .subscribe((e: any) => {
        saveLastPage(e.url);
        this.fullScreenLayout = !!e.urlAfterRedirects.includes('booking-confirmation-view');
        this.store.dispatch(AuthActions.setActiveStudioSite({ studioSite: +e.url.split('/')[1] }));
      });
  }

  public loadAppVersion(): void {
    this.appConfigService
      .loadVersionFromApi()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((version: IApiDataResponse<{ app_version: string; build?: string }>) => {
        this.appConfigService.version = version.data.app_version;
        this.appConfigService.versionBuild = version.data.build || '1';
      });
  }

  public onLogout(): void {
    this.store.dispatch(AuthActions.logoutConfirmation());
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
