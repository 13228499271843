import { addHours, format } from 'date-fns';
import { DateTime } from 'luxon';
import { dateFormatAPI } from '@env/environment';

// pass Date obj. and desired date-fns format to get formatted date string with UTC timezone
export const formatIsoDate = (date: Date, dateFormat: string, timezone?: number): string => {
  const formattedDate = date.toISOString();
  const [year, month, day]: any = formattedDate.substr(0, 10).split('-');
  const [hours, minutes]: any = formattedDate.substr(11, 8).split(':');
  const isoDate = new Date(year, month - 1, day, hours, minutes);
  if (timezone) {
    return format(addHours(isoDate, timezone), dateFormat);
  }
  return format(isoDate, dateFormat);
};

// parse date in UTC and return string in `yyyy-MM-dd HH:mm:ss` format
export const dateToUTCString = (date: Date): string => {
  const isoDate = date.toISOString();
  return `${isoDate.substr(0, 10)} ${isoDate.substr(11, 8)}`;
};

export function toDateView(date: Date): string {
  return DateTime.fromJSDate(date).toFormat(dateFormatAPI);
}

export function daysDifference(a: DateTime, b: DateTime): number {
  return Math.abs(a.startOf('day').diff(b.startOf('day'), ['days']).days);
}
