<div *ngIf="!(isLoggedIn$ | async) || fullScreenLayout; else baseLayout">
  <router-outlet></router-outlet>
</div>

<ng-template #baseLayout>
  <div class="d-flex">
    <div
      *ngIf="loader$ | async"
      class="page-loader"
    >
      <mat-spinner diameter="40"></mat-spinner>
    </div>

    <app-sidenav
      (logout)="onLogout()"
      [items]="navItems"
      [user]="user$ | async"
      class="flex-grow-1 sidenav position-sticky h-100"
    >
    </app-sidenav>

    <div class="app-scroll-container">
      <div
        class="app-container"
        *ngIf="studioSiteLoaded$ | async"
      >
        <router-outlet
          ngxInactivity
          (ngxInactivityCallback)="oktaSessionService.userIsActive()"
        ></router-outlet>
      </div>
    </div>
  </div>
</ng-template>
