import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginProcessorComponent } from '@auth/components/login-processor/login-processor.component';
import { LoginComponent } from '@auth/components/login/login.component';
import { LogoutComponent } from '@auth/components/logout/logout.component';

import { AuthGuard } from '@auth/services/auth.guard';
import { NoAuthGuard } from '@auth/services/no-auth.guard';
import { UserPermissionGuard } from '@auth/services/user-permission.guard';
import { AppContentContainerComponent } from '@core/containers/app-content-container/app-content-container.component';
import { HomePermissionGuard } from '@auth/services/home-permission.guard';
import { FeatureGuard } from '@core/feature-toggles';
import { PermissionsEnum } from '@shared/enums';
import { ThirdPartyStudioGuard } from '@core/third-party-studio';

const routes: Routes = [
  {
    path: 'login',
    children: [
      {
        path: '',
        component: LoginComponent,
      },
      {
        path: 'processor',
        component: LoginProcessorComponent,
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'studios',
    canActivate: [AuthGuard],
    loadChildren: () => import('@home/studios/studios.module').then((m) => m.StudiosModule),
  },
  {
    path: 'settings',
    canActivate: [AuthGuard, HomePermissionGuard],
    loadChildren: () => import('@home/settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: ':ssid',
    canActivate: [AuthGuard],
    component: AppContentContainerComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'bookings',
      },
      {
        path: 'bookings',
        canActivate: [AuthGuard],
        loadChildren: () => import('./bookings/bookings.module').then((m) => m.BookingsModule),
      },
      {
        path: 'schedule',
        canActivate: [AuthGuard, UserPermissionGuard],
        data: {
          permissionSlug: PermissionsEnum.menuSchedule,
        },
        loadChildren: () => import('./schedule/schedule.module').then((m) => m.ScheduleModule),
      },
      {
        path: 'session-sheets',
        canActivate: [AuthGuard, UserPermissionGuard],
        data: {
          permissionSlug: PermissionsEnum.menuSessionSheets,
        },
        loadChildren: () => import('./session-sheets/session-sheets.module').then((m) => m.SessionSheetsModule),
      },
      {
        path: 'invoices',
        canActivate: [AuthGuard, UserPermissionGuard],
        data: {
          permissionSlug: PermissionsEnum.menuInvoices,
        },
        loadChildren: () => import('./invoices/invoices.module').then((m) => m.InvoicesModule),
      },
      {
        path: 'purchase-orders',
        canActivate: [AuthGuard, UserPermissionGuard, ThirdPartyStudioGuard],
        data: {
          permissionSlug: PermissionsEnum.menuPurchaseOrders,
        },
        loadChildren: () => import('./purchase-orders/purchase-orders.module').then((m) => m.PurchaseOrdersModule),
      },
      {
        path: 'contacts',
        canActivate: [AuthGuard, UserPermissionGuard],
        data: {
          permissionSlug: PermissionsEnum.contacts,
        },
        loadChildren: () => import('./contacts/contacts.module').then((m) => m.ContactsModule),
      },
      {
        path: 'settings',
        canActivate: [AuthGuard, UserPermissionGuard],
        data: {
          permissionSlug: PermissionsEnum.menuSettings,
        },
        loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: 'reports',
        canActivate: [AuthGuard, UserPermissionGuard],
        data: {
          permissionSlug: PermissionsEnum.menuReports,
        },
        loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
