import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';

import { AppConfigService } from '@shared/services/app-config.service';

import * as AuthActions from '../../store/auth.actions';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  public isOauth2 = false;
  public loginForm = this.formBuilder.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store,
    private appConfigService: AppConfigService
  ) {}

  ngOnInit(): void {
    const conf = this.appConfigService.configs;
    this.isOauth2 = !!(conf.auth_mode === 'oauth2');
  }

  public loginClassic(): void {
    this.store.dispatch(AuthActions.loginClassic({ ...this.loginForm.value }));
  }

  public loginUSM(): void {
    this.store.dispatch(AuthActions.loginUSM());
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
