export enum BookingStatusesEnum {
  ArQuery = 1,
  ArHold = 2,
  NewQuery = 3,
  OpenQuery = 4,
  Hold = 5,
  ArConfirmed = 8,
  ConfSent = 9,
  Confirmed = 10,
  SelfConf = 11,
  Cancelled = 12,
  ToBeInvoiced = 14,
  Closed = 16,
  InvoiceSent = 17,
  InvoicePaid = 18,
  InvoiceClosed = 19,
}
