/**
 * This list of classes usually has 'overflow: hidden' in a table
 */
import {
  dateFormatAPI,
  dateShortYearFormatUI,
  time12TimePickerUI,
  time24FormatFullUI,
} from '@env/environment';
import { DateTime } from 'luxon';

let elements = [
  // '.ag-body-viewport',
  // '.ag-center-cols-clipper',
  // '.ag-center-cols-viewport',
  // '.ag-theme-material',
  // '.ag-root-wrapper',
  // '.ag-layout-auto-height',
  '.ag-root',
];

export function setOutsideCellForTable(tagName: string): void {
  mapperTagWithClasses(tagName).forEach((item) => {
    const element = document.querySelector(item);
    element?.setAttribute('style', 'overflow: visible');
  });
}

export function removeOutsideCellForTable(tagName: string): void {
  mapperTagWithClasses(tagName).forEach((item: string) => {
    const element = document.querySelector(item);
    element?.removeAttribute('style');
  });
}

export function mapperTagWithClasses(tagName: string): string[] {
  return elements.map((item: string) => `${tagName} ${item}`);
}

export function convertToFullStringDateTime(dateStr: string, timeStr: string): DateTime {
  const dateValue = DateTime.fromFormat(dateStr, dateShortYearFormatUI).toFormat(dateFormatAPI);
  const timeValue = DateTime.fromFormat(timeStr, time12TimePickerUI).toFormat(time24FormatFullUI);

  return DateTime.fromFormat(`${dateValue} ${timeValue}`, `${dateFormatAPI} ${time24FormatFullUI}`);
}
