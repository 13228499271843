export function waitElement(options: {
  selector: string;
  callbackOnFound: (element: Element) => void;
  callbackOnTimeout?: () => void;
  targetNode?: Element | null;
  timeout?: number;
}): void {
  // For best performance use a custom targetNode
  const targetNode: Element | undefined = options?.targetNode ?? document.body;
  const elementOnSetup = targetNode?.querySelector(options.selector);
  if (elementOnSetup) {
    options.callbackOnFound(elementOnSetup);
    return;
  }

  const timeout = options?.timeout ?? 5000;
  const config = { attributes: false, childList: true, subtree: true };
  let timerId: number;

  function callback(mutations: MutationRecord[], observer: MutationObserver) {
    const elementOnSearch = targetNode?.querySelector(options.selector);

    if (elementOnSearch) {
      options.callbackOnFound(elementOnSearch);
      observer.disconnect();
      clearTimeout(timerId);
    }
  }

  const observer = new MutationObserver(callback);

  observer.observe(targetNode, config);

  // @ts-ignore
  timerId = setTimeout(() => {
    observer.disconnect();
    if (options.callbackOnTimeout) {
      options.callbackOnTimeout();
    }
    console.warn(`Didn't wait for element ${options.selector}`);
  }, timeout);
}
