<div [class]="'dialog dialog--' + dialogWidthClass">
  <app-return-previous-modal
    *ngIf="showPrevBtn"
    [btnText]="prevBtnLabel"
    (backEvt)="clickPrev()"
  ></app-return-previous-modal>

  <div class="sticky-dialog-content pb-3 plr-2">
    <div
      matDialogTitle
      class="dialog-title mb-0"
    >
      <ng-container *ngIf="isOneItem">
        <img
          *ngIf="selectedItem?.icon"
          [src]="selectedItem?.icon"
          [class]="selectedItem?.iconClass"
        />
        <span [class]="selectedItem?.titleClass">
          {{ selectedItem?.title }}
          <ng-content select="[dialogTitleSuffix]"></ng-content>
        </span>
      </ng-container>

      <ng-container *ngIf="!isOneItem">
        <app-selectable-submenu
          *ngIf="mode === 'default'"
          [submenuItems]="submenuItems"
          [selectableItem]="selectableMenuItem"
          (selectedItem)="onSelectedItem($event)"
        ></app-selectable-submenu>
        <ng-container *ngIf="mode === 'toggle'">
          <span class="fs-24 fw-black mb-0 d-block">
            {{ selectedItem?.title }}
          </span>
          <p class="dialog-description my-0 mb-3 fw-regular color-black-50 fs-14px">
            {{ selectedItem?.description }}
          </p>
          <app-button-toggle-group
            class="toggle-mode-switch"
            [buttonToggleFormControl]="buttonToggleFormControl"
            [buttonToggleGroup]="buttonToggleGroup"
          ></app-button-toggle-group>
        </ng-container>
      </ng-container>

      <button
        class="dialog-close-btn"
        mat-icon-button
        tabindex="-1"
        [matDialogClose]=""
        (click)="closeDialog()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <p
      *ngIf="selectedItem?.description && (mode === 'default' || isOneItem)"
      [class]="'dialog-description my-0 ' + selectedItem?.descriptionClass"
    >
      {{ selectedItem?.description }}
    </p>
  </div>
  <app-card
    type="shadow-none"
    clases="h-100"
    [loading]="loading"
  >
    <div class="dialog-content plr-2 {{ customDialogMargin }}">
      <ng-content select="[dialogContent]"></ng-content>
    </div>

    <div class="dialog-actions plr-2">
      <ng-content select="[dialogActions]"></ng-content>
    </div>
  </app-card>
</div>
