import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CoreState, coreStateFeatureKey } from './core.reducers';

export const selectCoreState = createFeatureSelector<CoreState>(coreStateFeatureKey);

export const selectNavBarState = createSelector(selectCoreState, (state) => state.sidenavOpen);

export const selectPreviousUrl = createSelector(selectCoreState, (state) => state.previousUrl);

export const selectFullPagePending = createSelector(selectCoreState, (state) => state.fullPagePending);
