import { LocalStorageEnum } from '@shared/enums/local-storage.enum';

export function saveLastPage(path: string): void {
  const lastPage = localStorage.getItem(LocalStorageEnum.LastPageStudioSite);
  const idStudioSite = path.split('/')[1];
  let lastPageObj = parseJson(lastPage);

  if (['login', 'logout', 'home'].includes(idStudioSite)) {
    return;
  }

  lastPageObj[idStudioSite] = path;
  localStorage.setItem(LocalStorageEnum.LastPageStudioSite, JSON.stringify(lastPageObj));
}

export function getLastPage(idStudioSite: number): string {
  const lastPage = localStorage.getItem(LocalStorageEnum.LastPageStudioSite);
  const lastPageObj = parseJson(lastPage);
  return lastPageObj[idStudioSite];
}

function parseJson(value: string | null): any {
  if (!value) {
    return {};
  }

  try {
    return JSON.parse(value);
  } catch {
    return {};
  }
}
