<div
  class="d-inline-flex flex-column position-relative"
  [ngClass]="{ 'dialog-submenu': type === 'dialog' }"
>
  <section
    class="d-inline-flex align-items-center"
    [class.pointer]="submenuItems?.length! > 1"
  >
    <div
      *ngIf="selectableItem?.icon && !isSubmenuDisabled"
      class="d-flex align-items-center mr-16 submenu-icon"
    >
      <img [src]="selectableItem?.icon" />
    </div>
    <span class="title fs-32 fw-black">
      {{ selectableItem?.title }}
    </span>

    <img
      *ngIf="!isSubmenuDisabled && (submenuItems?.length || 0) > 1"
      src="./assets/icons/spinner.svg"
      alt="img"
      class="img-more"
    />
  </section>

  <div
    *ngIf="submenuItems?.length && !isSubmenuDisabled"
    [class.open-list]="openList"
    [@animateSelectableSubmenu]="openList && submenuItems?.length ? 'show' : 'hide'"
    class="flex-column position-absolute index-999 submenu-list"
  >
    <section
      *ngFor="let item of submenuItems"
      class="d-inline-flex align-items-center pointer mb-15 submenu-item"
      (click)="selectItem($event, item)"
    >
      <div
        *ngIf="item.icon"
        class="d-flex align-items-center mr-16 submenu-icon"
      >
        <img [src]="item.icon" />
      </div>
      <span
        [ngClass]="{
          'pl-2': !item.icon
        }"
        class="title fw-black"
      >
        {{ item.title }}
      </span>
    </section>
  </div>
</div>
