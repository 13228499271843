import { Pipe, PipeTransform } from '@angular/core';
import { clearExtraSpace } from '@shared/utils';

@Pipe({
  name: 'autoCompleteSearch',
})
export class AutoCompleteSearchPipe implements PipeTransform {
  transform(data: any[], search: string): any[] {
    if (!data || typeof search === 'object') {
      return [];
    }
    const words = (clearExtraSpace(search?.toLowerCase()) || '').split(' ');

    return data.filter((v) => {
      const splitName = v?.name.split(' ');
      const nameFirstLetters = splitName.map((fullName: string) => fullName[0]).join('');
      const searchStr = `${search?.length > 1 ? v?.name : nameFirstLetters} ${
        v?.description || ''
      } ${v?.value}`.toLowerCase();
      let hasAllWords = true;
      words.forEach((word: any) => {
        if (!searchStr.includes(word)) {
          hasAllWords = false;
        }
      });
      return hasAllWords;
    });
  }
}
