import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewRef,
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { SubmenuItem } from './models/selectable-submenu-item.interface';

@Component({
  selector: 'app-selectable-submenu',
  templateUrl: './app-selectable-submenu.component.html',
  styleUrls: ['./app-selectable-submenu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('animateSelectableSubmenu', [
      state(
        'hide',
        style({
          opacity: 0,
        })
      ),
      state(
        'show',
        style({
          opacity: 1,
        })
      ),
      transition('hide => show', [animate('125ms ease-in')]),
    ]),
  ],
})
export class AppSelectableSubmenuComponent implements OnChanges {
  private pristineArray: SubmenuItem[] | undefined;

  public openList = false;
  @Input() isSubmenuDisabled: boolean | null = false;

  @Input() submenuItems: SubmenuItem[] | undefined;
  @Input() selectableItem: SubmenuItem | undefined;
  @Input() type: 'primary' | 'dialog' = 'primary';

  @Output() readonly selectedItem = new EventEmitter<SubmenuItem>();

  @HostListener('click', ['$event'])
  clickInside(event: Event): void {
    if (this.submenuItems?.length === 1) {
      return;
    }
    this.openList = true;
    this.detectChanges();
  }

  @HostListener('document:click', ['$event'])
  clickOut(event: Event): void {
    if (this.openList && !this.eRef.nativeElement.contains(event.target)) {
      this.openList = false;
      this.detectChanges();
    }
  }

  constructor(private eRef: ElementRef, private renderer: Renderer2, private cdRef: ChangeDetectorRef) {
    this.cdRef.detach();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.submenuItems?.currentValue.length) {
      this.selectableItem = this.selectableItem || changes.submenuItems.currentValue[0];
      this.pristineArray = changes.submenuItems.currentValue;
    }
    if (changes.selectableItem?.currentValue) {
      this.selectableItem = changes.selectableItem.currentValue;
      this.sortList(changes.selectableItem.currentValue);
    }
    this.submenuItems = this.submenuItems?.filter((item) => item.hasPermission);
    // first we update the template, after which we run the image check
    // 1 - this.detectChanges()
    // 2 - this.waitLastImageLoaded()
    this.detectChanges();
    this.waitLastImageLoaded();
  }

  private waitLastImageLoaded(): void {
    const images = this.eRef.nativeElement.querySelectorAll('app-selectable-submenu .submenu-list .submenu-icon img');

    if (!images.length) {
      return;
    }

    const listToRemoveListener: (() => void)[] = [];
    let counter = 0;

    for (const item of images) {
      const listener = this.renderer.listen(item, 'load', (arg) => {
        counter++;
        if (counter === images.length) {
          // this.setIconsSize();

          // when all images are loaded, we remove listeners so as not to produce holes in memory
          if (listToRemoveListener.length) {
            for (const itemListener of listToRemoveListener) {
              itemListener();
            }
          }
        }
      });

      listToRemoveListener.push(listener);
    }
  }

  private setIconsSize(): void {
    const submenuIcons = this.eRef.nativeElement.querySelectorAll('app-selectable-submenu .submenu-icon');

    if (!submenuIcons.length) {
      return;
    }

    let maxHeight = 0;
    let maxWidth = 0;

    for (const icon of submenuIcons) {
      // clear previous installation height and width //////////
      this.renderer.removeStyle(icon, 'height');
      this.renderer.removeStyle(icon, 'width');
      //////////////////////////////////////////

      if (icon.offsetHeight > maxHeight) {
        maxHeight = icon.offsetHeight;
      }
      if (icon.offsetWidth > maxWidth) {
        maxWidth = icon.offsetWidth;
      }
    }

    if (maxHeight > 0 && maxWidth > 0) {
      for (const icon of submenuIcons) {
        this.renderer.setStyle(icon, 'height', maxHeight + 'px');
        this.renderer.setStyle(icon, 'width', maxWidth + 'px');
      }
    }
  }

  private detectChanges(): void {
    if (!(this.cdRef as ViewRef).destroyed) {
      this.cdRef.detectChanges();
    }
  }

  private sortList(item: SubmenuItem): void {
    if (!this.submenuItems || !this.pristineArray) {
      return;
    }

    const newArr = [...this.pristineArray];
    const index = newArr.findIndex((i) => i.id === item.id);

    newArr.splice(index, 1);
    newArr.unshift(item);

    this.submenuItems = newArr.filter((item) => item.hasPermission);
  }

  public selectItem(event: Event, item: SubmenuItem): void {
    if (this.submenuItems?.length === 1) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    this.selectableItem = item;
    this.sortList(item);
    this.selectedItem.emit(item);

    this.openList = false;
    this.cdRef.detectChanges();
  }
}
