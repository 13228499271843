import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';

import { AppToasterComponent } from './app-toaster.component';

@NgModule({
  declarations: [AppToasterComponent],
  imports: [CommonModule, MatIconModule],
  exports: [AppToasterComponent],
})
export class AppToasterModule {}
