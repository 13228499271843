import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localeIt from '@angular/common/locales/it';
import localeAt from '@angular/common/locales/de-AT';
import localeEs from '@angular/common/locales/es';
import localeGb from '@angular/common/locales/en-GB';
import localeMx from '@angular/common/locales/es-MX';
import localeSv from '@angular/common/locales/sv';
import localeAu from '@angular/common/locales/en-AU';
import localeKo from '@angular/common/locales/ko';
import localeNb from '@angular/common/locales/nb';
import localeZar from '@angular/common/locales/en-ZA';
import localePl from '@angular/common/locales/pl';
import localeBRL from '@angular/common/locales/pt';

import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '@env/environment';
import { CoreRouteReuseStrategy } from '@core/core.route-reuse-strategy';
import { LoadingInterceptor } from '@core/interceptors/loading.interceptor';
import { AppComponent } from '@core/containers/app/app.component';
import { CoreModule } from '@core/core.module';
import { CoreHttpErrorInterceptor } from '@core/interceptors/core.http-error.interceptor';
import { CoreHttpInterceptor } from '@core/interceptors/core.http.interceptor';
import { AuthModule } from '@auth/auth.module';
import { loadUserInfoOnInitialize } from '@auth/store/auth.actions';
import { AppConfigService } from '@shared/services/app-config.service';
import { metaReducers } from './reducers';
import { AppRoutingModule } from './app-routing.module';
import { LocalStorageEnum } from '@shared/enums/local-storage.enum';
import { LocaleService } from '@shared/services/locale.service';
import { ThirdPartyStudioModule } from '@core/third-party-studio';

registerLocaleData(localeEn, 'en');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeIt, 'it');
registerLocaleData(localeAt, 'de-AT');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeGb, 'en-GB');
registerLocaleData(localeMx, 'es-MX');
registerLocaleData(localeSv, 'sv');
registerLocaleData(localeAu, 'en-AU');
registerLocaleData(localeKo, 'ko');
registerLocaleData(localeNb, 'nb');
registerLocaleData(localeZar, 'en-ZA');
registerLocaleData(localePl, 'pl');
registerLocaleData(localeBRL, 'pt');

export function initConfigs(appConfigService: AppConfigService, store$: Store): any {
  return async (): Promise<any> => {
    appConfigService.loadVersion();
    return await appConfigService
      .loadConfigs()
      .toPromise()
      .then(() => {
        store$.dispatch(loadUserInfoOnInitialize());
      });
  };
}

export function appInitializerTranslateFactory(translate: TranslateService): any {
  return () => {
    translate.setDefaultLang('en');
    return translate.use('en').toPromise();
  };
}

export function clearAllSearchFiltersActiveSS() {
  const authRaw = localStorage.getItem(LocalStorageEnum.Auth);
  const dictionarySearchFiltersRaw = localStorage.getItem(LocalStorageEnum.DictionaryFilters);
  const searchConditionsRaw = localStorage.getItem(LocalStorageEnum.SearchConditions);

  let studioSiteId = 0;
  let dictionarySearchFilters;
  let searchConditions: any;

  if (authRaw) {
    studioSiteId = JSON.parse(authRaw)?.activeStudioSite?.studioSiteId;
  }

  if (searchConditionsRaw) {
    searchConditions = JSON.parse(searchConditionsRaw);
  }

  if (!studioSiteId || !dictionarySearchFiltersRaw || !searchConditionsRaw) {
    return () => null;
  }

  if (dictionarySearchFiltersRaw) {
    dictionarySearchFilters = JSON.parse(dictionarySearchFiltersRaw);
  }

  dictionarySearchFilters.forEach((item: string) => {
    delete searchConditions[`${studioSiteId}${item}`];
  });

  return () => localStorage.setItem(LocalStorageEnum.SearchConditions, JSON.stringify(searchConditions));
}

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    EffectsModule.forRoot([]),
    AuthModule,
    CoreModule,
    BrowserModule,
    HttpClientModule,
    HammerModule,
    CommonModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers,
      }
    ),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    StoreRouterConnectingModule.forRoot(),
    ThirdPartyStudioModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initConfigs,
      deps: [AppConfigService, Store],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerTranslateFactory,
      deps: [TranslateService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: clearAllSearchFiltersActiveSS,
      deps: [],
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useFactory: (localeService: LocaleService) => localeService.getCurrentLocale(),
      deps: [LocaleService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: CoreHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CoreHttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: CoreRouteReuseStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
