import { Injectable } from '@angular/core';
import { Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { FeatureService } from './feature.service';
import { FeatureToggleName } from './feature-toggle.interface';

@Injectable({
  providedIn: 'root',
})
export class FeatureGuard {
  constructor(private featureTogglesService: FeatureService, private router: Router) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const feature: FeatureToggleName | undefined = route.data?.feature;

    if (feature && this.featureTogglesService.isEnabled(feature)) {
      return true;
    }

    this.router.navigate(['/']);
    return false;
  }
}
