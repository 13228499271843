import { Injectable } from '@angular/core';
import { LocalStorageEnum } from '@shared/enums/local-storage.enum';
import { FeatureService } from '@core/feature-toggles';

export interface LogRecord {
  date: string;
  source: string;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class LsLogService {
  private readonly MAX_LOGS = 200;
  private loggingEnabled = false;

  constructor(private featureService: FeatureService) {
    this.loggingEnabled = this.featureService.isEnabled('ft-auth-logging');

    if (!this.loggingEnabled) {
      localStorage.removeItem(LocalStorageEnum.LogRecords);
      return;
    }
  }

  public addLog(source: string, message: string): void {
    if (!this.loggingEnabled) {
      return;
    }

    const logs = this.getLogs();
    const date = new Date().toISOString();
    const newLog: LogRecord = { date, source, message };

    if (logs.length >= this.MAX_LOGS) {
      logs.shift(); // remove the oldest log
    }

    logs.push(newLog);
    localStorage.setItem(LocalStorageEnum.LogRecords, JSON.stringify(logs));
  }

  private getLogs(): LogRecord[] {
    const logs = localStorage.getItem(LocalStorageEnum.LogRecords);
    return logs ? JSON.parse(logs) : [];
  }
}
