<div
  class="vh-100 pt-1 overflow-y-auto"
  [ngClass]="darkMode ? 'dark-mode' : 'sidenav-channels'"
>
  <div
    class="channel-item m-3 d-flex justify-content-center align-items-center position-relative pointer image-holder home-button is-navigate-grid-outside-click"
    title="Home"
    [class.active]="(activeStudiosite$ | async)?.studioSiteId === 0"
    (click)="goHome()"
  >
    <img
      src="./assets/icons/sidenav/home-icon.svg"
      alt=""
    />
  </div>
  <div
    *ngFor="let channel of channels$ | async"
    class="channel-item m-3 d-flex justify-content-center align-items-center position-relative pointer is-navigate-grid-outside-click"
    [class.active]="channel.studioSiteId === (activeStudiosite$ | async)?.studioSiteId"
    [class.image-holder]="channel.iconLink"
    [title]="channel.studioSiteName"
    (click)="selectChannel(channel)"
  >
    <ng-container *ngTemplateOutlet="channel.iconLink ? image : placeholder; context: { channel: channel }">
    </ng-container>
  </div>
  <!-- It will be implemented later -->
  <!--  <div class="channel-item channel-item-add m-3 d-flex align-items-center justify-content-center">-->
  <!--    <mat-icon>add</mat-icon>-->
  <!--  </div>-->
</div>

<ng-template
  #image
  let-channel="channel"
>
  <img
    [src]="channel.iconLink | authImage: true"
    [defaultImage]="null"
    (imageBroken)="channel.icon_link = ''"
    alt=""
  />
</ng-template>

<ng-template
  #placeholder
  let-channel="channel"
>
  <span class="text-uppercase fs-20 fw-medium">
    {{ getChannelInitials(channel) }}
  </span>
</ng-template>
