import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UserModel } from 'src/app/shared/models/auth/user.model';
import { PermissionsEnum } from '@shared/enums/permissions.enum';
import { dateShortYearFormatUI } from '@env/environment';

import { authFeatureKey, AuthState } from './auth.reducer';

export const selectAuthState = createFeatureSelector<AuthState>(authFeatureKey);

export const selectStudioSites = createSelector(selectAuthState, (state: AuthState) => state.studioSites);

export const selectActiveStudioSite = createSelector(selectAuthState, (state: AuthState) => state.activeStudioSite);

export const selectActiveStudioSiteId = createSelector(selectAuthState, (state: AuthState) =>
  state.activeStudioSite ? state.activeStudioSite.studioSiteId : 0
);

export const selectActiveStudioSiteTimeZone = createSelector(selectAuthState, (state: AuthState) =>
  state.activeStudioSite ? state.activeStudioSite.timezone : ''
);

export const selectThirdPartyStudio = createSelector(
  selectAuthState,
  (state: AuthState) => state.activeStudioSite?.thirdPartyStudio ?? false
);

export const selectActiveStudioSiteCancelTableChangesByClickOutside = createSelector(
  selectAuthState,
  (state: AuthState) => (state.activeStudioSite ? state.activeStudioSite.cancelTableChangesByClickOutside : false)
);

export const selectActiveStudioSiteLoaded = createSelector(
  selectAuthState,
  (state: AuthState) => state.activeStudioSiteLoaded
);

export const getUser = createSelector(selectAuthState, (state: AuthState) => state.user);

export const isAuthUSM = createSelector(selectAuthState, (state) => state.isUsmAuth);

export const getUserToken = createSelector(getUser, (user: null | UserModel) => (user?.token ? user.token : null));

export const getLoggedIn = createSelector(getUser, (user) => !!user);

export const isExchangeTokenLoaded = createSelector(selectAuthState, (state) => state.exchangeTokenLoaded);

export const selectActiveStudioSiteDateFormat = createSelector(
  selectAuthState,
  (state: AuthState) => state.activeStudioSite?.dateFormat ?? dateShortYearFormatUI
);

export const isUserArRole = createSelector(selectAuthState, (state: AuthState) =>
  !!state.activeStudioSite?.studioSiteId &&
  !!state.user?.permissions &&
  state.user.permissions[state.activeStudioSite.studioSiteId]
    ? state.user.permissions[state.activeStudioSite.studioSiteId][PermissionsEnum.isAr] ||
      state.user.permissions[state.activeStudioSite.studioSiteId][PermissionsEnum.isScheduleUser]
    : false
);

export const getUserPermissions = createSelector(selectAuthState, (state: AuthState) =>
  state.user?.permissions && state.activeStudioSite.studioSiteId
    ? state.user?.permissions[state.activeStudioSite.studioSiteId]
    : null
);

export const getGlobalPreferences = createSelector(selectAuthState, (state: AuthState) =>
  state.user?.preferences?.globalPrefs ? state.user.preferences.globalPrefs : null
);
