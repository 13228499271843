import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export function getInputChanges$<T>(
  form: AbstractControl | null,
  controlName: string,
  debounce = 500
): Observable<T> {
  const valueChanges = form?.get(controlName)?.valueChanges;
  if (!valueChanges) {
    throw new Error(`Control ${controlName} not found`);
  }
  return valueChanges.pipe(debounceTime(debounce));
}

export function disableControls(parentControl: AbstractControl, childControls: string[]): void {
  childControls.forEach((controlName) => parentControl.get(controlName)?.disable());
}
export function enableControls(parentControl: AbstractControl, childControls: string[]): void {
  childControls.forEach((controlName) => parentControl.get(controlName)?.enable());
}
