import { createReducer, on } from '@ngrx/store';

import { UserModel } from '@shared/models/auth/user.model';
import { StudioSite } from '@shared/models/auth/studiosite.model';

import * as AuthActions from './auth.actions';
import { LocalStorageEnum } from '@shared/enums/local-storage.enum';

export const authFeatureKey = 'auth';

export interface AuthState {
  user: UserModel | null;
  error: any;
  isUsmAuth: boolean;
  exchangeTokenLoaded: boolean;
  studioSites: StudioSite[];
  activeStudioSiteLoaded: boolean;
  activeStudioSite: StudioSite;
}

export const initialState: AuthState = {
  user: null,
  error: null,
  isUsmAuth: false,
  exchangeTokenLoaded: false,
  studioSites: [],
  activeStudioSiteLoaded: false,
  activeStudioSite: {
    studioSiteId: 0,
    studioSiteName: '',
    studioSiteSlug: '',
    studioSiteShortName: '',
    timezone: '',
    currencyCode: '',
    iconLink: '',
    organization: 0,
    dateFormat: '',
    is24hTimeFormat: false,
    roleName: '',
    miniVersion: false,
    thirdPartyStudio: false,
    invoicingNotRequired: false,
    cmsAccessOnly: false,
    allowRatesForMiniVersion: false,
    cancelTableChangesByClickOutside: false,
    primaryBookingAsset: '',
  },
};

export const authReducer = createReducer(
  initialState,
  on(AuthActions.setAuthTypeUsm, (state, { isUsm }) => ({
    ...state,
    isUsmAuth: isUsm,
  })),
  on(AuthActions.loginSuccess, (state, data) => ({
    ...state,
    user: {
      ...data.user,
      userRoles: state.user?.userRoles?.length ? state.user.userRoles : data.user.userRoles,
    },
  })),
  on(AuthActions.updateTokenInStore, (state, data) => ({
    ...state,
    user: state.user ? { ...state.user, token: data.token } : null,
  })),

  on(AuthActions.loginUSM, (state) => {
    return { ...state, exchangeTokenLoaded: false };
  }),
  on(AuthActions.exchangeToken, (state) => {
    return { ...state, exchangeTokenLoaded: true };
  }),

  on(AuthActions.studioSitesLoadSuccess, (state, data) => ({
    ...state,
    studioSites: data.studioSites,
  })),
  on(AuthActions.setActiveStudioSite, (state, data) => {
    let activeStudioSite = initialState.activeStudioSite;
    if (data.studioSite) {
      activeStudioSite =
        state.studioSites?.find((studioSite) => studioSite.studioSiteId === data.studioSite) ||
        initialState.activeStudioSite;
    }

    if (activeStudioSite.studioSiteId) {
      localStorage.setItem(LocalStorageEnum.LastActiveStudioSite, `${activeStudioSite.studioSiteId}`);
    }

    return {
      ...state,
      activeStudioSiteLoaded: true,
      activeStudioSite,
    };
  }),

  on(AuthActions.userAddField, (state, userUpdate) => ({
    ...state,
    user: { ...state.user, ...userUpdate.data },
  })),

  on(AuthActions.clearUser, (state) => ({
    ...state,
    user: null,
  })),

  on(AuthActions.logoutSuccess, AuthActions.logoutFailure, AuthActions.userTokenExpired, (state) => ({
    ...initialState,
    activeStudioSite: state.activeStudioSite,
  })),

  on(AuthActions.loginFailure, (state, error) => ({
    ...state,
    error,
  }))
);
