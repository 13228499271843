import { createAction, props } from '@ngrx/store';

import { ContactsStatusesEnum } from '@shared/modules/contacts/enums';

import {
  AddressType,
  Country,
  Company,
  CompanyRole,
  MatchSapCompany,
  CompanyLinkedPerson,
  CompanyBillingDetails,
} from '@shared/modules/contacts/models';
import { CompanyBillingMethodEnum } from '@shared/enums';
import { NameValueEmpty } from '@shared/models';

export const cancelLoadCompanyDetailsRequest = createAction('[ContactCompany/UI] Load Company Details');

export const loadCompanyDetailsRequest = createAction(
  '[ContactCompany/API] Load Company Details',
  props<{ contactCompanyId: number }>()
);
export const loadCompanyDetailsRequestSuccess = createAction(
  '[ContactCompany/API] Load Company Details Success',
  props<{ data: Company }>()
);
export const loadCompanyDetailsRequestFailure = createAction(
  '[ContactCompany/API] Load Company Details Failure',
  props<{ error?: any }>()
);
// Company linked persons
export const loadPersonListLinkedToCompanyRequest = createAction(
  '[ContactCompany/API] Load Person List Linked To Company',
  props<{ contactCompanyId: number }>()
);
export const loadPersonListLinkedToCompanyRequestSuccess = createAction(
  '[ContactCompany/API] Load Person List Linked To Company Success',
  props<{ data: CompanyLinkedPerson[] }>()
);
export const loadPersonListLinkedToCompanyRequestFailure = createAction(
  '[ContactCompany/API] Load Person List Linked To Company Failure',
  props<{ error: any }>()
);

export const updateActiveCompany = createAction(
  '[ContactCompany/UI] Update Active Company in Store',
  props<{ data: Company }>()
);

export const clearActiveCompany = createAction('[ContactCompany/UI] Clear Active Company in Store');

export const addContactsCompanyPersonRequest = createAction(
  '[ContactCompany/API] Add Person To Company',
  props<{ studioContact?: number; sonyUserPrincipalName?: string; studioCompany: number; roleInCompany: string }>()
);
export const addContactsCompanyPersonRequestSuccess = createAction(
  '[ContactCompany/API] Add Person To Company Success',
  props<{ persons: CompanyLinkedPerson[] }>()
);
export const addContactsCompanyPersonRequestFailure = createAction(
  '[ContactCompany/API] Add Person To Company Failure',
  props<{ error: any }>()
);

export const updatePersonInContactsCompanyRequest = createAction(
  '[ContactCompany/API] Update Contacts Company Person Role',
  props<{ studioContact: number; studioCompany: number; roleInCompany: string }>()
);
export const updatePersonInContactsCompanyRequestSuccess = createAction(
  '[ContactCompany/API] Update Contacts Company Person Role Success',
  props<{ persons: CompanyLinkedPerson[] }>()
);
export const updatePersonInContactsCompanyRequestFailure = createAction(
  '[ContactCompany/API] Update Contacts Company Person Role Failure',
  props<{ error: any }>()
);

export const deleteContactsCompanyPersonRequest = createAction(
  '[ContactCompany/API] Delete Company To Person',
  props<{ studioContact: number; studioCompany: number }>()
);
export const deleteContactsCompanyPersonRequestSuccess = createAction(
  '[ContactCompany/API] Delete Company To Person Success',
  props<{ persons: CompanyLinkedPerson[] }>()
);
export const deleteContactsCompanyPersonRequestFailure = createAction(
  '[ContactCompany/API] Delete Company To Person Failure',
  props<{ error: any }>()
);

export const createContactsCompanyRequest = createAction(
  '[ContactCompany/API] Create Contacts Company',
  props<{ company: Company }>()
);
export const createContactsCompanyRequestSuccess = createAction(
  '[ContactCompany/API] Create Contacts Company Success',
  props<{ data: Company }>()
);
export const createContactsCompanyRequestFailure = createAction(
  '[ContactCompany/API] Create Contacts Company Failure',
  props<{ error: any }>()
);

export const updateContactsCompany = createAction(
  '[ContactCompany/API] Update Contacts Company',
  props<{ company: Company }>()
);
export const updateContactsCompanySuccess = createAction(
  '[ContactCompany/API] Update Contacts Company Success',
  props<{ data: Company }>()
);
export const updateContactsCompanyFailure = createAction(
  '[ContactCompany/API] Update Contacts Company Failure',
  props<{ error: any }>()
);

export const updateContactsCompanyStatus = createAction(
  '[ContactCompany/API] Update Contacts Company Status',
  props<{ status: ContactsStatusesEnum }>()
);
export const updateContactsCompanyStatusSuccess = createAction(
  '[ContactCompany/API] Update Contacts Company Status Success',
  props<{ data: Company }>()
);
export const updateContactsCompanyStatusFailure = createAction(
  '[ContactCompany/API] Update Contacts Company Status Failure',
  props<{ error: any }>()
);

export const loadContactsStudioSiteCompanyRolesRequest = createAction(
  '[ContactCompany/API] Load Contacts/Company Roles for specific Studio Site'
);
export const loadContactsStudioSiteCompanyRolesRequestSuccess = createAction(
  '[ContactCompany/API] Load Contacts/Company Roles for specific Studio Site Success',
  props<{ data: NameValueEmpty[] }>()
);
export const loadContactsStudioSiteCompanyRolesRequestFailure = createAction(
  '[ContactCompany/API] Load Contacts/Company Roles for specific Studio Site Failure',
  props<{ error: any }>()
);

export const redirectToPersonById = createAction(
  '[ContactCompany/API] Redirect to person by Id',
  props<{ personId: number | null }>()
);

// Countries List
export const loadCountriesListRequest = createAction('[ContactCompany/API] Load Countries List');

export const loadCountriesListRequestSuccess = createAction(
  '[ContactCompany/API] Load Countries List Success',
  props<{ data: Country[] }>()
);

export const loadCountriesListRequestFailure = createAction(
  '[ContactCompany/API] Load Countries List Failure',
  props<{ error: any }>()
);

// Address Types List
export const loadAddressTypesRequest = createAction('[ContactCompany/API] Load Address Types List');

export const loadAddressTypesRequestSuccess = createAction(
  '[ContactCompany/API] Load Address Types List Success',
  props<{ data: AddressType[] }>()
);

export const loadAddressTypesRequestFailure = createAction(
  '[ContactCompany/API] Load Address Types List Failure',
  props<{ error: any }>()
);

//Company Billing Details
export const loadCompanyBillingDetailsRequest = createAction(
  '[ContactCompany/API] Load Company Billing Details',
  props<{ studioCompany: number }>()
);

export const loadCompanyBillingDetailsRequestSuccess = createAction(
  '[ContactCompany/API] Load Company Billing Details Success',
  props<{ data: CompanyBillingDetails }>()
);

export const loadCompanyBillingDetailsRequestFailure = createAction(
  '[ContactCompany/API] Load Company Billing Details Failure',
  props<{ error: any }>()
);

export const updateBillingDetailsLetterSubjectRequest = createAction(
  '[ContactCompany/API] Update Company Billing Details Subject',
  props<{ studioCompany: number; letterSubject: string; copyTo: string[] | null }>()
);

export const updateBillingDetailsLetterSubjectRequestFailure = createAction(
  '[ContactCompany/API] Update Company Billing Details Subject Failure',
  props<{ error: any }>()
);

export const cancelCompanySapRequest = createAction(
  '[ContactCompany/API] Cancel Company Sap',
  props<{
    studioCompany: number;
    sapInProgress?: boolean;
    sapCompanyStatus?: number;
    showResultMsg?: boolean;
  }>()
);

export const cancelCompanySapRequestSuccess = createAction(
  '[ContactCompany/API] Cancel Company Sap Success',
  props<{ data: Company }>()
);

export const cancelCompanySapRequestFailure = createAction(
  '[ContactCompany/API] Cancel Company Sap Failure',
  props<{ error: any }>()
);

export const unSyncSonyCompany = createAction(
  '[ContactCompany/API] Unsync Sony Company',
  props<{ studioCompany: number }>()
);
export const unSyncSonyCompanySuccess = createAction(
  '[ContactCompany/API] Unsync Sony Company Success',
  props<{ data: Company }>()
);
export const unSyncSonyCompanyFailure = createAction(
  '[ContactCompany/API] Unsync Sony Company Failure',
  props<{ error: any }>()
);

export const loadAvailableSapCompaniesRequest = createAction('[ContactCompany/API] Load Available Sap Companies');

export const loadAvailableSapCompaniesRequestSuccess = createAction(
  '[ContactCompany/API] Load Available Sap Companies Success',
  props<{ data: MatchSapCompany[] }>()
);

export const loadAvailableSapCompaniesRequestFailure = createAction(
  '[ContactCompany/API] Load Available Sap Companies Failure',
  props<{ error: any }>()
);

export const loadMatchSapCompanyInformationRequest = createAction(
  '[ContactCompany/API] Load Match Sap Company Information',
  props<{ sapCompanyCode: string; sapCustomer: string }>()
);

export const loadMatchSapCompanyInformationRequestSuccess = createAction(
  '[ContactCompany/API] Load Match Sap Company Information Success',
  props<{ data: MatchSapCompany }>()
);

export const loadMatchSapCompanyInformationRequestFailure = createAction(
  '[ContactCompany/API] Load Match Sap Company Information Failure',
  props<{ error: any }>()
);

export const loadCompaniesForSapMatchRequest = createAction(
  '[ContactCompany/API] Load Companies For Sap Match',
  props<{ companyType: string | string[]; sapInProgress?: boolean }>()
);

export const loadCompaniesForSapMatchRequestSuccess = createAction(
  '[ContactCompany/API] Load Companies For Sap Match Success',
  props<{ data: Company[] }>()
);

export const loadCompaniesForSapMatchRequestFailure = createAction(
  '[ContactCompany/API] Load Companies For Sap Match Failure',
  props<{ error: any }>()
);

export const discardCompanySapRequest = createAction(
  '[ContactCompany/API] Discard Company Sap',
  props<{
    sapCompanyCode: string;
    sapCustomer: string;
    sapCompanyStatus: number;
  }>()
);

export const discardCompanySapRequestFailure = createAction(
  '[ContactCompany/API] Discard Company Sap Failure',
  props<{ error: any }>()
);

export const acceptMatchSapCompanyInformationRequest = createAction(
  '[ContactCompany/API] Accept Match Sap Company Information',
  props<{
    sapCompanyCode: string;
    sapCustomer: string;
    studioCompany: number;
  }>()
);

export const acceptMatchSapCompanyInformationRequestFailure = createAction(
  '[ContactCompany/API] Accept Match Sap Company Information Failure',
  props<{ error: any }>()
);

export const updateCompanyBillingMethod = createAction(
  '[ContactCompany/API] Update Company Billing Method',
  props<{ billingMethod: CompanyBillingMethodEnum }>()
);
export const updateCompanyBillingMethodSuccess = createAction(
  '[ContactCompany/API] Update Company Billing Method Success',
  props<{ data: Company }>()
);
export const updateCompanyBillingMethodFailure = createAction(
  '[ContactCompany/API] Update Company Billing Method Failure',
  props<{ error: any }>()
);

export const loadCompanyDiscount = createAction('[ContactCompany/API] Load Company Discount');
export const loadCompanyDiscountSuccess = createAction(
  '[ContactCompany/API] Load Company Discount Success',
  props<{ data: number[] }>()
);
export const loadCompanyDiscountFailure = createAction(
  '[ContactCompany/API] Load Company Discount Failure',
  props<{ error: any }>()
);

export const clearSapInfo = createAction('[ContactCompany/UI] Clear SAP related info');
