import { createAction, props } from '@ngrx/store';
import { UserModel } from '@shared/models/auth/user.model';
import { StudioSite } from '@shared/models/auth/studiosite.model';
import { IUserAuth } from '@shared/models/auth/user-auth.interface';

export const setAuthTypeUsm = createAction('[Auth] set Auth type USM', props<{ isUsm: boolean }>());

export const loginClassic = createAction('[Auth] Login Classic', props<{ username: string; password: string }>());

export const loginUSM = createAction('[Auth] Login USM');
export const exchangeToken = createAction('[AUTH] Exchange token', props<{ exchange_token: string }>());

export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<{ user: UserModel; isSimpleRefresh?: boolean; redirectToStudioSiteId?: number }>()
);
export const loginFailure = createAction('[Auth] Login Failure', props<{ error: any }>());

export const updateTokenInStore = createAction('[Auth] Update token in store', props<{ token: string }>());

export const loadUserInfo = createAction(
  '[Auth] Load user info',
  props<{ token: string; isSimpleRefresh?: boolean; redirectToStudioSiteId?: number }>()
);

export const loadStudioSites = createAction(
  '[Auth] Load Studio Sites',
  props<{ isSimpleRefresh?: boolean; redirectToStudioSiteId?: number; refreshStudioSiteId?: number }>()
);

export const studioSitesLoadSuccess = createAction(
  '[Auth] Studio Sites Load Success',
  props<{ studioSites: StudioSite[] }>()
);

export const setActiveStudioSite = createAction(
  '[Auth] Set Active StudioSite',
  props<{ studioSite: number | null; redirectToSs?: boolean }>()
);

export const navigateToAvailableStudioSite = createAction(
  '[Auth] Navigate To Available StudioSite',
  props<{ withPageReload?: boolean }>()
);

export const setUserContactInfo = createAction('[Auth] Set User Contact Info');

export const loginRedirect = createAction('[Auth] Login Redirect', props<{ source: string; message: string }>());

export const userAddField = createAction('[Auth] Update user field', props<any>());

export const logout = createAction('[Auth] Logout', props<{ withRequest: boolean; source: string; message: string }>());
export const logoutSuccess = createAction('[Auth] Logout Success');
export const logoutFailure = createAction('[Auth] Logout Failure', props<{ error: any }>());
export const logoutConfirmation = createAction('[Auth] Logout Confirmation');

export const userTokenLifetime = createAction('[Auth] Token Lifetime', props<{ user: UserModel | null }>());
export const userTokenExpired = createAction('[Auth] Token Expired');

export const clearUser = createAction('[Auth] Clear User State');

export const pingUSM = createAction('[AUTH USM] Ping server', props<{ token: string }>());
export const pingUSMSuccess = createAction('[AUTH USM] Ping server Success', props<{ data: IUserAuth }>());
export const pingUSMFailure = createAction('[AUTH USM] Ping server Failure', props<{ error: any }>());
export const loadUserInfoOnInitialize = createAction('[Auth] load user info on initialize');
