import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-return-previous-modal',
  templateUrl: './app-return-previous-modal.component.html',
  styleUrls: ['./app-return-previous-modal.component.scss'],
})
export class AppReturnPreviousModalComponent implements OnInit {
  @Input() btnText = `${this.translateService.instant('SHARED_MODULE.BACK_DIALOG')}`;
  @Output() backEvt: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {}

  clickBack(): void {
    this.backEvt.emit(true);
  }
}
