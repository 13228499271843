<ng-container *ngIf="user?.photo">
  <img
    [src]="user.photo | authImage"
    [defaultImage]="''"
    (imageBroken)="user.photo = ''"
    [class]="'img-center ' + classes"
    alt="user photo"
  />
</ng-container>

<ng-container *ngIf="!user?.photo && userInitials">
  <span
    [class]="
      'user-initials fs-16 w-100 h-100 d-flex align-items-center justify-content-center ' + classes
    "
    [ngClass]="{ 'bg-primary': !user?.tintColor }"
    [ngStyle]="{ 'background-color': user?.tintColor }"
  >
    {{ userInitials }}
  </span>
</ng-container>

<ng-container *ngIf="!user?.photo && !userInitials">
  <div class="avatar"></div>
</ng-container>
