import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { getLoggedIn } from '../store/auth.selectors';
import * as AuthActions from '../store/auth.actions';

@Injectable()
export class NoAuthGuard {
  constructor(private store: Store<any>) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const path: string = route.url[0].path;

    return this.store.pipe(
      select(getLoggedIn),
      first(),
      map((isLoggedIn) => {
        if (isLoggedIn && path === 'logout') {
          this.store.dispatch(
            AuthActions.logout({ withRequest: false, source: 'noAuthGuard', message: 'logout by route' })
          );
          return false;
        }

        return true;
      })
    );
  }
}
