<app-auth-page-layout *ngIf="!isOauth2">
  <div
    cardContent
    class="d-flex flex-column align-items-center"
  >
    <form
      [formGroup]="loginForm"
      (ngSubmit)="loginClassic()"
      class="login d-flex flex-column align-items-center"
    >
      <div class="title">
        {{ "GENERAL.CONSOLE" | translate }}:<span class="title-studio">{{ "GENERAL.STUDIO" | translate }}</span>
      </div>
      <div class="description fs-14 text-center">
        <p class="p-0 m-0">{{ "AUTH_MODULE.WELCOME_MESSAGE" | translate }}</p>
        <p class="p-0 m-0">{{ "AUTH_MODULE.APP_DESCRIPTION" | translate }}</p>
      </div>

      <app-input
        [label]="'GENERAL.EMAIL_ADDRESS' | translate"
        formControlName="username"
        [showStarAlways]="true"
        class="w-100"
      ></app-input>

      <app-input
        [label]="'SHARED_MESSAGES.PASSWORD' | translate"
        type="password"
        [showStarAlways]="true"
        formControlName="password"
        class="w-100 my-4"
      ></app-input>

      <app-button
        color="primary"
        [label]="'AUTH_MODULE.SIGN_IN' | translate"
        class="w-100"
        classes="w-100"
      ></app-button>
    </form>
  </div>
</app-auth-page-layout>

<app-auth2-page-layout *ngIf="isOauth2">
  <div
    USMCardContent
    class="d-flex flex-column align-items-center"
  >
    <img
      class="description-logo"
      src="./assets/images/logo.svg"
    />
    <div class="description fs-14 text-center">
      <p class="p-0 m-0">{{ "AUTH_MODULE.WELCOME_MESSAGE" | translate }}</p>
      <p class="p-0 m-0">{{ "AUTH_MODULE.APP_DESCRIPTION" | translate }}</p>
    </div>
    <app-button
      [type]="'button'"
      (click)="loginUSM()"
      color="thunder"
      [label]="'AUTH_MODULE.SIGN_IN' | translate"
      class="w-100"
      classes="w-100"
    ></app-button>
  </div>
</app-auth2-page-layout>
