import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThirdPartyStudioDirective } from '@core/third-party-studio/third-party-studio.directive';

@NgModule({
  declarations: [ThirdPartyStudioDirective],
  imports: [CommonModule],
  exports: [ThirdPartyStudioDirective],
})
export class ThirdPartyStudioModule {}
