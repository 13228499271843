import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthModule } from 'src/app/auth/auth.module';

import { AuthImagePipe } from './pipes/auth-image/auth-image.pipe';
import { DefaultImageDirective } from './directives/default-image.directive';
import { ImageTokenService } from './services/image-token.service';
import { GalleryImagePipe } from '@shared/pipes/auth-image/pipes/auth-image/gallery-image.pipe';

@NgModule({
  imports: [CommonModule, AuthModule],
  declarations: [DefaultImageDirective, AuthImagePipe, GalleryImagePipe],
  providers: [ImageTokenService],
  exports: [DefaultImageDirective, AuthImagePipe, GalleryImagePipe],
})
export class ImageHelperModule {}
