export enum Colors {
  Primary = '#5457ff',
  White = '#FFFFFF',
  Black = '#000000',
  GrayChateau = '#9CA3AF',
  GrayAthens = '#E1E3E7',
  GraySantas = '#B2B2B2',
  Mist = '#EFF2F7',
  BrandBlack = '#1a1f36',
  Disabled = '#f8f8f8',
  NonBooking = '#4DABFB',
  Grey = '#d7dadf',
  Yellow = '#FFC800',
  MatterhornSnow = '#ebfef9',
}
