import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { LoadingService } from '../service/loading.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private loadingService: LoadingService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const uniqueId = (Math.random() + 1).toString(36).substring(7);

    this.loadingService.setLoading(true, req.url, req.method, uniqueId);

    return next.handle(req).pipe(
      finalize(() => {
        this.loadingService.setLoading(false, req.url, req.method, uniqueId);
      })
    );
  }
}
