<app-dialog
  [items]="[
    {
      title: 'AUTH_MODULE.SIGN_OUT_QUESTION' | translate
    }
  ]"
  dialogWidthClass="medium"
>
  <div
    dialogActions
    class="d-flex justify-content-between align-items-center w-100"
  >
    <app-button
      type="button"
      size="small"
      [label]="'SHARED_MESSAGES.CANCEL' | translate"
      [mat-dialog-close]="false"
    ></app-button>
    <app-button
      type="button"
      size="small"
      color="secondary"
      [label]="'AUTH_MODULE.SIGN_OUT' | translate"
      [mat-dialog-close]="true"
    ></app-button>
  </div>
</app-dialog>
