export enum SchedulerViewMode {
  MainInfo,
  NotesTranced,
  NotesExpanded,
}

export enum SchedulerViewTitleSizeMode {
  CommonFontSizeMode,
  EnlargedFontSizeMode,
}
