import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IStudiositePreference } from '@shared/models/auth/studiosite-preference.interface';
import { StudiositePreference } from '@shared/models/auth/studiosite-preference.model';
import { AppConfigService } from '@shared/services/app-config.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IUserAuth } from '@shared/models/auth/user-auth.interface';
import { IStudiositePermission } from '@shared/models/auth/studiosite-permission.interface';
import { StudiositePermission } from '@shared/models/auth/studiosite-permission.model';
import { IStudioSite } from '@shared/models/auth/studiosite.interface';
import { UserTypeEnum } from '@shared/enums/user-type.enum';
import { GlobalPreference } from '@shared/models/auth/global-preference.model';
import { IGlobalPreference } from '@shared/models/auth/global-preference.interface';
import { JSON_FORMAT } from '@shared/global-constants/json-format';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public user: any;

  constructor(private http: HttpClient, private appConfigService: AppConfigService) {}

  public login(username: string, password: string): Observable<{ data: IUserAuth; status: any }> {
    const formData = new FormData();

    formData.append('username', username);
    formData.append('password', password);
    return this.http.post<{ data: IUserAuth; status: any }>(`${environment.BASE_PATH}/login`, formData);
  }

  public logout(): Observable<any> {
    return this.http.post(`${environment.BASE_PATH}/logout`, {});
  }

  public logoutUSM(user: any): string {
    const postLogoutRedirectUrl = `${window.location.protocol}//${window.location.host}/sc/logout`;
    return `${this.appConfigService.configs.api_url}/logout/oauth2/${user.token}?state=${btoa(postLogoutRedirectUrl)}`;
  }

  public retrieveUser(token: string): Observable<{ data: IUserAuth; status: any }> {
    const headers = {
      'Token-Type': 'Bearer',
      'Access-Token': token,
    };
    return this.http.get<{ data: IUserAuth; status: any }>(`${environment.BASE_PATH}/v1/me`, {
      headers,
    });
  }

  public pingUSM(token: string): Observable<{ data: IUserAuth; status: any }> {
    const headers = {
      'Token-Type': 'Bearer',
      'Access-Token': token,
    };
    return this.http.post<{ data: IUserAuth; status: any }>(`${environment.BASE_PATH}/v1/me`, { token }, { headers });
  }

  public loginUSM(): string {
    const nonce = (length: number): string => {
      let prefix = '';
      const base = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for (let i = 0; i < length; i++) {
        prefix += base.charAt(Math.floor(Math.random() * base.length));
      }
      return prefix + new Date().getTime();
    };

    const conf = this.appConfigService.configs;
    const pAutorizedUri = conf.oauth2_url;
    const pClientId = conf.client_id;
    const pNonce = nonce(3);
    const wl = window.location;
    const uiLandingBase = `${wl.protocol}//${wl.host}${wl.pathname.replace('/login', '/')}`;
    const uiLandingUrl = `${uiLandingBase}login/processor`;
    const structure = JSON.stringify({
      c: pClientId,
      n: pNonce,
      r: uiLandingUrl,
    });
    const pState = btoa(structure);
    const redirectUri = conf.redirect_uri;
    const apiUrl = conf.api_url;
    const pRedirectUri = redirectUri
      ? encodeURIComponent(redirectUri).replace(/\./g, '%2E')
      : encodeURIComponent(`${apiUrl}/login/oauth2/validate`).replace(/\./g, '%2E');
    const postLogoutRedirectUri = encodeURIComponent(`${uiLandingBase}logout`).replace(/\./g, '%2E');
    const href = `${pAutorizedUri}?response_type=code&scope=openid&client_id=${pClientId}&state=${pState}&nonce=${pNonce}&redirect_uri=${pRedirectUri}&post_logout_redirect_uri=${postLogoutRedirectUri}`;
    return href;
  }

  public exchangeToken(exchange_token: string): Observable<string> {
    return this.http
      .post<{ meta: any; data: string }>(`${environment.BASE_PATH}/v3/exchange_tokens/exchange/`, {
        exchange_token,
      })
      .pipe(map((res) => res.data));
  }

  public parsePermissions(raw?: {
    [key: string]: IStudiositePermission;
  }): { [key: string]: StudiositePermission } | null {
    if (!raw || !Object.keys(raw).length) {
      return null;
    }

    const result: { [key: string]: StudiositePermission } = {};
    Object.keys(raw).forEach((key: string) => (result[key] = new StudiositePermission(raw[key])));
    return result;
  }

  public getUserType(permissions?: { [key: string]: StudiositePermission }): UserTypeEnum {
    if (!permissions || !Object.keys(permissions).some((key: string) => permissions[key])) {
      return UserTypeEnum.schedule;
    }

    return UserTypeEnum.common;
  }

  public parsePreferences(raw?: {
    studiosite_prefs?: { [key: string]: IStudiositePreference };
    global_prefs?: IGlobalPreference;
  }): {
    studiositePrefs: { [key: string]: StudiositePreference } | null;
    globalPrefs: GlobalPreference | null;
  } | null {
    if (!raw || !Object.keys(raw).length) {
      return null;
    }

    const result: {
      studiositePrefs: { [key: string]: StudiositePreference } | null;
      globalPrefs: GlobalPreference | null;
    } | null =
      (!!raw.studiosite_prefs && Object.keys(raw.studiosite_prefs)) ||
      (!!raw.global_prefs && Object.keys(raw.global_prefs))
        ? {
            studiositePrefs: Object.keys(raw.studiosite_prefs ?? {}) ? {} : null,
            globalPrefs: raw.global_prefs ? new GlobalPreference(raw.global_prefs) : null,
          }
        : null;
    Object.keys(raw.studiosite_prefs ?? {}).forEach(
      (key: string) => (result!.studiositePrefs![key] = new StudiositePreference(raw.studiosite_prefs![key]))
    );
    return result;
  }

  public getStudioSites(): Observable<{ data: { rows: IStudioSite[] }; meta: any }> {
    return this.http.post<any>(
      `${environment.BASE_PATH}/v3/widgets/left_side_menu_studiosite_list/data/0/detailed`,
      {},
      { headers: JSON_FORMAT }
    );
  }
}
