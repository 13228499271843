import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { UserModel } from '@shared/models/auth/user.model';
import { map, take } from 'rxjs/operators';
import { Observable, zip } from 'rxjs';

import { getUser, isAuthUSM } from '../store/auth.selectors';
import * as AuthActions from '../store/auth.actions';

@Injectable()
export class AuthGuard {
  constructor(private store: Store<any>) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const path: string = route.url[0].path;

    return zip(this.store.pipe(select(getUser)), this.store.pipe(select(isAuthUSM))).pipe(
      map(([user, isUsmAuth]: [UserModel | null, boolean]) => {
        if (!user && path !== 'login') {
          localStorage.setItem('returnUrl', state.url);
          this.store.dispatch(AuthActions.loginRedirect({ source: 'authGuard', message: 'no user' }));
          return false;
        } else if (!!user && path === 'login') {
          this.store.dispatch(AuthActions.navigateToAvailableStudioSite({}));
          return false;
        }
        if (path !== 'login' && !isUsmAuth) {
          this.store.dispatch(AuthActions.userTokenLifetime({ user }));
        }

        return true;
      }),
      take(1)
    );
  }
}
