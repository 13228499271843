<div [ngClass]="classes">
  <div class="w-100 h-100 card-content-wrapper">
    <ng-content></ng-content>
  </div>
  <div
    *ngIf="loading"
    class="d-flex align-items-center justify-content-center p-3 loading-overlay position-absolute"
  >
    <mat-spinner diameter="40"></mat-spinner>
  </div>
</div>
