import { FeatureToggle } from './feature-toggle.interface';

export const FEATURE_TOGGLES: FeatureToggle[] = [
  {
    name: 'ft-sony-companies',
    description: 'Sony companies',
    defaultValue: false,
    jira: [],
    date: '2024-01-18',
  },
  {
    name: 'ft-duplicate-bftt',
    description: 'duplicate in BFTT',
    defaultValue: true,
    jira: ['SC-20126'],
    date: '2024-01-29',
  },
  {
    name: 'ft-auth-logging',
    description: 'auth logging to local storage',
    defaultValue: true,
    jira: ['SC-20597'],
    date: '2024-02-01',
  },
  {
    name: 'ft-cms-access-only',
    description: 'temporary hidden',
    defaultValue: false,
    jira: ['SC-21087'],
    date: '2024-02-28',
  },
];
