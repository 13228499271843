import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ImageHelperModule } from 'src/app/shared/pipes/auth-image/image-helper.module';

import { AppUserPhotoComponent } from './app-user-photo.component';

@NgModule({
  declarations: [AppUserPhotoComponent],
  imports: [CommonModule, ImageHelperModule],
  exports: [AppUserPhotoComponent],
})
export class AppUserPhotoModule {}
