export enum BookingPeopleContactTypeEnum {
  QueryContact = 'Query Contact',
  ClientContact = 'Client Contact',
  BillingContact = 'Billing Contact',
  Artist = 'Artist',
  Engineer = 'Engineer',
  Assistant = 'Assistant',
  Producer = 'Producer',
  ArApprover = 'A&R Approver',
}
