import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import { environment } from '@env/environment';

// investigate why this brokes tables
// if (environment.production) {
//   enableProdMode();
// }
enableProdMode();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

import Quill from 'quill';
let AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);
