import { Injectable } from '@angular/core';
import { Subject, ReplaySubject } from 'rxjs';

import { AuthService } from './auth.service';

/**
 * Notifier service
 * Provides means for notifications via web-socket protocol
 */
@Injectable()
export class NotifierService {
  readonly ajaxInterval: number = 3000;

  private ajaxSubscr: any = null;
  private cache: any = [];

  readonly listenType = {
    job_type: new ReplaySubject<any>(),
    kicked_session_type: new Subject<any>(),
    user_banned_type: new Subject<any>(),
  };
  readonly events = {
    messageProcessed: new Subject<string>(),
  };
  private initialPingInterval: any;

  constructor(private authService: AuthService) {}

  start(token: any, lifeTime: any): void {
    this.initialPingInterval = setInterval(() => {
      this.startAjax(token, Date.now() + lifeTime * 1000);
    }, this.ajaxInterval);
  }

  startAjax(token: any, tokenExpiresAt: any): void {
    if (tokenExpiresAt > Date.now() && token) {
      this.authService.retrieveUser(token).subscribe(
        (resp: any) => {
          for (const i of Object.keys(resp)) {
            this.handleProcessMessage(resp[i]);
          }
        },
        (err: any) => {
          clearTimeout(this.ajaxSubscr);
          this.ajaxSubscr = setTimeout(() => {
            this.startAjax(token, tokenExpiresAt);
          }, this.ajaxInterval);
        },
        () => {
          clearTimeout(this.ajaxSubscr);
          this.ajaxSubscr = setTimeout(() => {
            this.startAjax(token, tokenExpiresAt);
          }, this.ajaxInterval);
        }
      );
    } else {
      if (!(typeof this.initialPingInterval === 'undefined')) {
        this.initialPingInterval = clearInterval(this.initialPingInterval);
      }
    }
  }

  stop(): void {
    if (this.ajaxInterval) {
      clearInterval(this.ajaxSubscr);
    }
  }

  handleProcessMessage(message: any): void {
    if (
      !(
        Object.keys(this.cache).length &&
        this.cache[message.id] &&
        this.cache[message.id].appeared === message.appeared
      )
    ) {
      switch (message.type) {
        case 'kicked_session_type':
          this.listenType.kicked_session_type.next(null);
          break;
        case 'user_banned_type':
          this.listenType.user_banned_type.next(null);
          break;
      }
    }
    if (message.id && message.appeared) {
      this.cache[message.id] = {
        appeared: message.appeared,
        id: message.id,
        now: Date.now(),
      };
    }
    this.clearCache();
  }

  clearCache(): any {
    if (Object.keys(this.cache).length) {
      Object.keys(this.cache).map((key) => {
        if (Date.now() - this.cache[key].now > 30000) {
          delete this.cache[key];
        }
      });
    }
  }
}
