import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

import { ImageTokenService } from '../../services/image-token.service';

@Pipe({
  name: 'authImage',
})
export class AuthImagePipe implements PipeTransform {
  constructor(private imageTokenService: ImageTokenService) {}

  private mapUrl(url: string): string {
    const userToken = this.imageTokenService.getLastUpdatedToken();
    return userToken ? mapTokenToUrl(url, userToken) : url;
  }

  public transform(url?: string | null, thumbnail: boolean = false): string {
    if (!url) {
      return '';
    }

    const generatedUrl = this.mapUrl(url);

    return thumbnail ? generatedUrl + '?thumbnail' : generatedUrl;
  }
}

const mapTokenToUrl = (url: string, token: string): string => {
  const isExternal = url.match(/\.?\/?external_assets\//);
  const regexp = isExternal ? /\.?\/?external_assets\// : /\.?\/?assets\//;
  const pathToFile = url.replace(regexp, '');
  const assets = isExternal ? 'external_assets' : 'assets';
  return `${environment.BASE_PATH}/${assets}/${token}/${pathToFile}`;
};
