import { createAction, props } from '@ngrx/store';

import { SchedulerFilterItem } from '@shared/modules/scheduler/models/scheduler-filter-item.model';

import { IPerson } from '@shared/modules/contacts/models';

import { IPrefilledScheduleBooking, Booking } from '@shared/models';
import { IBookingArtistGRPS } from '../models/booking-artist-grps.interface';
import { AddNewPersonToBookingContacts } from '../interfaces/add-new-person-to-contacts.interface';
import { ContactRole } from '../models/contact-role.model';
import { SchedulerViewMode, SchedulerViewTitleSizeMode } from '@shared/enums';
import { CreateBookingModelUpdated } from '../models/create-booking-updated.model';
import { SettingsApproversDefaultToggle } from '@settings/settings-bookings/settings-ar-approvers-table/models';
import { CancelEventModeEnum } from '../enums';
import { StudioEnquiryModeEnum } from '@shared/modules/scheduler/modules/studio-enquiry';

export const changeSchedulerLayoutMode = createAction(
  '[AppDataManagementHeader/UI] Change Scheduler Layout Mode (Day/Week/Month/Year)',
  props<{ data: number; skipScrollToFirstDay?: boolean }>()
);

export const changeSchedulerFilters = createAction(
  '[AppDataManagementHeader/UI] Change Scheduler Filters (selected rows to be shown)',
  props<{ data: SchedulerFilterItem[] | undefined }>()
);

export const clearSchedulerFilters = createAction(
  '[AppDataManagementHeader/UI] Clear Scheduler Filters (selected rows to be shown)'
);

export const changeSchedulerEventTitleFontsize = createAction(
  '[AppDataManagementHeader/UI] Change Scheduler event first line font size',
  props<{ data: SchedulerViewTitleSizeMode }>()
);

export const changeSchedulerEventExtention = createAction(
  '[AppDataManagementHeader/UI] Change Scheduler event type of view (with comments block or not)',
  props<{ data: SchedulerViewMode }>()
);

export const openCreateBookingModal = createAction(
  '[AppDataManagementHeader/Modal] Open Booking Modal',
  props<{ data: IPrefilledScheduleBooking }>()
);

export const openStudioEnquiryModal = createAction(
  '[AppDataManagementHeader/Modal] Open Studio Enquiry Modal',
  props<{ data?: IPrefilledScheduleBooking }>()
);

export const openCreateBookingModalSuccess = createAction('[AppDataManagementHeader/Modal] Open Booking Modal Success');
export const openCreateBookingModalFailure = createAction(
  '[AppDataManagementHeader/Modal] Open Booking Modal Failure',
  props<{ error: any }>()
);

export const setBackButtonPath = createAction(
  '[AppDataManagementHeader/API] Set Back Button Path',
  props<{ data: string }>()
);

export const addBookingUpdated = createAction(
  '[AppDataManagementHeader/API] Add Booking Updated',
  props<{ booking: CreateBookingModelUpdated; redirectTo: string | null }>()
);
export const addBookingUpdatedSuccess = createAction(
  '[AppDataManagementHeader/API] Add Booking Updated Success',
  props<{ booking: Booking; redirectTo: string | null; startDate?: Date | null }>()
);
export const addBookingUpdatedFailure = createAction(
  '[AppDataManagementHeader/API] Add Booking Updated Failure',
  props<{ error: any }>()
);

export const addBookingArtist = createAction(
  '[AppDataManagementHeader/API] Add Booking Artist',
  props<{ studiosite: number; artistName: string }>()
);
export const addBookingArtistSuccess = createAction(
  '[AppDataManagementHeader/API] Add Bookings Artist Success',
  props<{ artist: any }>()
);
export const addBookingArtistFailure = createAction(
  '[AppDataManagementHeader/API] Add Bookings Artist Failure',
  props<{ error: any }>()
);

export const addBookingArtistGRPS = createAction(
  '[AppDataManagementHeader/API] Add Booking GRPS Artist',
  props<{ studiosite: number; artist: IBookingArtistGRPS }>()
);
export const addBookingArtistGRPSSuccess = createAction(
  '[AppDataManagementHeader/API] Add Bookings GRPS Artist Success',
  props<{ artist: any }>()
);
export const addBookingArtistGRPSFailure = createAction(
  '[AppDataManagementHeader/API] Add Bookings GRPS Artist Failure',
  props<{ error: any }>()
);

export const createClientCompany = createAction(
  '[AppDataManagementHeader/API] Create Company Client',
  props<{ company: any }>()
);
export const createClientCompanySuccess = createAction(
  '[AppDataManagementHeader/API] Create Company Clients Success',
  props<{ company: any }>()
);
export const createClientCompanyFailure = createAction(
  '[AppDataManagementHeader/API] Create Company Clients Failure',
  props<{ error: any }>()
);

export const addNewPersonToBookingContact = createAction(
  '[AppDataManagementHeader/API] Put New Person To Booking Contacts',
  props<{ data: AddNewPersonToBookingContacts }>()
);
export const addNewPersonToBookingContactSuccess = createAction(
  '[AppDataManagementHeader/API] Put New Person To Booking Contacts Success',
  props<{ data: IPerson }>()
);
export const addNewPersonToBookingContactFailure = createAction(
  '[AppDataManagementHeader/API] Put New Person To Booking Contacts Failure',
  props<{ error: any }>()
);

export const createClientRole = createAction(
  '[AppDataManagementHeader/API] Create Client Role',
  props<{ data: any }>()
);
export const createClientRoleSuccess = createAction(
  '[AppDataManagementHeader/API] Create Client Role Success',
  props<{ data: ContactRole }>()
);
export const createClientRoleFailure = createAction(
  '[AppDataManagementHeader/API] Create Client Role Failure',
  props<{ error: any }>()
);

export const schedulerLastBookingView = createAction(
  '[AppDataManagementHeader/UI] Enable Scheduler Event Highlight',
  props<{ value: boolean; booking_id: number | null; firstEventStartDate: Date | null }>()
);

export const schedulerScrollToDay = createAction(
  '[AppDataManagementHeader/UI] User press button "Today" on the scheduler page'
);

export const setTodayButtonEnabled = createAction(
  '[AppDataManagementHeader/UI] Set today button enabled',
  props<{ value: boolean }>()
);

export const setSchedulerMonthViewDate = createAction(
  '[AppDataManagementHeader/UI] Set date for month-view scheduler header',
  props<{ start: Date; end: Date | null }>()
);

export const checkIsExistingContact = createAction(
  '[AppDataManagementHeader/API] Check Is Existing Contact',
  props<{ firstName: string; lastName: string; email?: string }>()
);
export const checkIsExistingContactSuccess = createAction(
  '[AppDataManagementHeader/API] Check Is Existing Contact Success',
  props<{ isExistingContact: boolean }>()
);
export const checkIsExistingContactFailure = createAction(
  '[AppDataManagementHeader/API] Check Is Existing Contact Failure',
  props<{ error: any }>()
);

export const loadDefaultApproverToggleStateRequest = createAction(
  '[AppDataManagementHeader/API] Load Default Approver Toggle State'
);
export const loadDefaultApproverToggleStateRequestSuccess = createAction(
  '[AppDataManagementHeader/API] Load Default Approver Toggle State Success',
  props<{ data: SettingsApproversDefaultToggle }>()
);
export const loadDefaultApproverToggleStateRequestFailure = createAction(
  '[AppDataManagementHeader/API] Load Default Approver Toggle State Failure',
  props<{ error: any }>()
);

export const updateCancelEventMode = createAction(
  '[AppDataManagementHeader/UI] Update Cancel Event Mode',
  props<{ mode: CancelEventModeEnum; reload: boolean }>()
);

export const loadCancelEventMode = createAction('[AppDataManagementHeader/UI] Load Cancel Event Mode');

export const clearNewPersonContact = createAction('[AppDataManagementHeader/UI] Clear New Person Contact');

// TODO: remove when refactor setEngineer and setArtist
export const openEditStudioEnquiryModal = createAction(
  '[AppDataManagementHeader/UI] Open Edit Studio Enquiry Modal',
  props<{
    editPayload: { booking: number; studiobooked: number };
    mode: StudioEnquiryModeEnum;
  }>()
);
