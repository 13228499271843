import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import { getUser } from '@auth/store/auth.selectors';
import * as CoreActions from '@core/store/core.actions';
import { selectActiveStudioSite } from '@auth/store/auth.selectors';
import { UserModel } from '@shared/models/auth/user.model';
import { AppConfigService } from '@shared/services/app-config.service';
import { NavItem } from '../../models/nav-item.interface';
import { StudiositePermission } from '@shared/models/auth/studiosite-permission.model';
import { StudioSite } from '@shared/models';
import { RoutesEnum } from '@shared/enums';
import { ActionGroupModalService } from '@shared/modules/modals/action-group-modal';

@Component({
  selector: 'app-sidenav',
  templateUrl: './app-sidenav.component.html',
  styleUrls: ['./app-sidenav.component.scss'],
})
export class AppSidenavComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() items: NavItem[] = [];
  @Input() user: UserModel | null;

  @Output() logout = new EventEmitter();

  private unsubscribe$ = new Subject<void>();
  private documentClick$: Subscription;

  public activeStudiosite$: Observable<StudioSite> = this.store.select(selectActiveStudioSite);

  private currentStudioSite: number = 0;

  public fixedNavItems: NavItem[] = [];
  public isArUser: any;
  public isOnHomePage: boolean = false;
  public isGlobalSettings: boolean = false;
  public darkMode: boolean = false;
  public isOpen = environment.production; // auto hide for side bar for ONLY dev mode. It good for working on notebook.
  public version: any;
  public versionBuild: any;
  public tokenExpire: any;
  public permissions: StudiositePermission | null = null;

  constructor(
    private eRef: ElementRef,
    private appConfigService: AppConfigService,
    private ngZone: NgZone,
    private store: Store,
    private readonly actionGroupModal: ActionGroupModalService
  ) {
    // SC-2179 we remove the code as temporally solution.  In the future, the logic will be complicated and auto-close will be resumed
    /*
    this.ngZone.runOutsideAngular(() => {
      this.documentClick$ = fromEvent<any>(document, 'click')
        .subscribe((event: any) => {
          if (
            this.isOpen
            && !this.eRef.nativeElement.contains(event.target)
            && !event?.target.classList.contains('cdk-overlay-backdrop')
            && !event?.target.classList.contains('menu-mat-icon')
          ) {
            this.closeMenu();
          }
        });
    });
    */
  }

  ngOnInit(): void {
    this.store
      .select(selectActiveStudioSite)
      .pipe(
        map((activeStudioSite: StudioSite) => {
          this.currentStudioSite = activeStudioSite?.studioSiteId || 0;
          return activeStudioSite?.studioSiteId;
        }),
        switchMap(() => this.store.pipe(select(getUser))),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((user: UserModel | null) => {
        this.permissions =
          !!user?.permissions && this.currentStudioSite ? user?.permissions[this.currentStudioSite] : null;
        this.isArUser = (this.permissions?.isAr || this.permissions?.isScheduleUser) ?? false;
        this.isOnHomePage = this.currentStudioSite === 0;
        this.isGlobalSettings = user?.preferences?.globalPrefs?.globalSettings ?? false;
        this.darkMode = this.isArUser || this.isOnHomePage;
        this.setupMenuItems();
      });

    if (this.isOpen) {
      document.querySelector('body')?.classList.add('opened');
    }
  }

  public ngAfterViewChecked(): void {
    this.version = this.appConfigService.version;
    this.versionBuild = this.appConfigService.versionBuild;
    if (this.user?.lifetime) {
      this.tokenExpire = Date.now() + this.user.lifetime * 1000 - this.user.lifetime * 1000;
    }
  }

  private documentClickUnsubscribe(): void {
    if (this.documentClick$) {
      this.documentClick$.unsubscribe();
    }
  }

  public onLogout(): void {
    this.logout.emit();
  }

  public openMenu(): void {
    this.isOpen = true;
    this.store.dispatch(CoreActions.openSidenav());
    document.querySelector('body')?.classList.add('opened');
  }

  public closeMenu(): void {
    this.isOpen = false;
    this.store.dispatch(CoreActions.closeSidenav());
    document.querySelector('body')?.classList.remove('opened');
    this.documentClickUnsubscribe();
  }

  isShow(item: NavItem, activeStudiosite?: number): boolean {
    if (
      activeStudiosite &&
      this.user?.permissions &&
      ((this.user?.permissions as { [key: string]: StudiositePermission })[activeStudiosite]?.isAr ||
        (this.user?.permissions as { [key: string]: StudiositePermission })[activeStudiosite]?.isScheduleUser)
    ) {
      return item.route === `/${activeStudiosite}/schedule`;
    }

    if (this.isOnHomePage) {
      if (item.route?.includes(RoutesEnum.homeDirectory)) {
        return true;
      }

      if (this.isGlobalSettings && item.route?.includes(RoutesEnum.homeSettings)) {
        return true;
      }
    }

    const permissionName: string | null = item.permissionName?.length ? item.permissionName : null;

    if (!activeStudiosite || !permissionName || !this.permissions) {
      return false;
    }
    return this.permissions[permissionName as keyof StudiositePermission] || false;
  }

  getUserNameAndRole(user: UserModel): string {
    let nameArr = [];
    if (user?.fullName) {
      nameArr.push(user.fullName);
    }
    if (user?.userRoles?.length) {
      nameArr = [...nameArr, ...user.userRoles.map((item) => item.role_name)];
    }
    return nameArr.join(', ');
  }

  public setupMenuItems(): void {
    if (this.items?.length) {
      if (this.isOnHomePage) {
        this.fixedNavItems = this.items.filter(
          (item) =>
            item.isHomeMenu &&
            (item.route === RoutesEnum.homeDirectory ||
              (item.route === RoutesEnum.homeSettings && this.isGlobalSettings))
        );
        return;
      }

      this.fixedNavItems = this.items.map((item: NavItem) => ({
        ...item,
        route: `/${this.currentStudioSite}${item.route}`,
      }));
    }
  }

  public openDownloadAppModal(): void {
    this.actionGroupModal.open({
      title: 'SIDENAV.DOWNLOAD_APP_TITLE',
      message: 'SIDENAV.DOWNLOAD_APP_MODAL_HINT',
      imageSrc: './assets/icons/download-app-icon.png',
      imageHeight: 80,
      imageWidth: 80,
      actions: [],
      showCloseButton: true,
      disableClose: true,
      contentImage: {
        src: './assets/images/download-app-qr.svg',
        height: 160,
        width: 160,
        containerClasses: 'd-flex justify-content-center mt-3',
      },
    });
  }

  public ngOnDestroy(): void {
    this.documentClickUnsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
