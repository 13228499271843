import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NavService } from 'src/app/shared/services/nav.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { NavItem, NavItemChild } from '../../models/nav-item.interface';

@Component({
  selector: 'app-menu-list-item',
  templateUrl: './app-menu-list-item.component.html',
  styleUrls: ['./app-menu-list-item.component.scss'],
})
export class AppMenuListItemComponent implements OnInit, OnDestroy {
  @Input() item!: NavItem | NavItemChild;
  @Input() first: boolean;
  @Input() user: any;
  @Input() isOpen: boolean;
  @Input() darkMode: boolean;
  @Input() isAr: boolean;

  private unsubscribe$ = new Subject<void>();

  constructor(public navService: NavService, public router: Router) {}

  ngOnInit(): void {
    this.navService.currentUrl
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((url) => !!url)
      )
      .subscribe(() => {});
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public onItemSelected(item: NavItem | NavItemChild): void {
    if (item.disabled) {
      return;
    }

    if (!item.children || !item.children.length) {
      this.router.navigate([item.route]);
    }
  }
}
