import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { AppCardComponent } from './app-card.component';

@NgModule({
  declarations: [AppCardComponent],
  imports: [CommonModule, MatProgressSpinnerModule],
  exports: [AppCardComponent],
})
export class AppCardModule {}
