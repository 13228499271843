export enum CurrenciesCodeEnum {
  US = 'en_US',
  Germany = 'de_DE',
  Italy = 'it_IT',
  Austria = 'de_AT',
  France = 'fr_FR',
  Spain = 'es_ES',
  GreatBritain = 'en_GB',
  Mexico = 'es_MX',
  Sweden = 'sv_SE',
  Australia = 'en_AU',
  Korea = 'ko_KR',
  Norway = 'nb_NO',
  SouthAfrica = 'en_ZA',
  Poland = 'pl_PL',
  Portugal = 'pt_BR',
}

export enum LocaleCodeEnum {
  US = 'en',
  Germany = 'de',
  Italy = 'it',
  Austria = 'de_AT',
  France = 'fr',
  Spain = 'es',
  GreatBritain = 'en_GB',
  Mexico = 'es_MX',
  Sweden = 'sv',
  Australia = 'en_AU',
  Korea = 'ko',
  Norway = 'nb',
  SouthAfrica = 'en_ZA',
  Poland = 'pl',
  Portugal = 'pt',
}
