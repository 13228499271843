export const environment = {
  production: true,
  BASE_PATH: '',
  defaultLocale: 'en',
  API_PORT: 8888,
};

export const dateFormatUI = 'MM/dd/yyyy';
export const dateTimeFormatUI = 'MM/dd/yyyy hh:mm a';
export const dateShortYearFormatUI = 'MM/dd/yy';
export const time12ShortTimeFormatUI = 'hh:mm a';
export const time12TimePickerUI = 'h:mm a';
export const time12FormatUI = 'hh:mm aaa';
export const time24FormatUI = 'hh:mm aaaa';
export const time24WithSpaceFormatUI = 'hh mm aaaa';
export const dateTimeFormatAPI = 'yyyy-MM-dd HH:mm:ss';
export const dateTimeFormatTimeZoneAPI = 'yyyy-MM-dd HH:mm:ssZZ';
export const dateFormatAPI = 'yyyy-MM-dd';
export const time24FormatFullUI = 'HH:mm:ss';
export const time24FormatNotFullUI = 'HH:mm';
export const dateLongMonthFormatUI = 'do MMMM yyyy';
export const dateLong24MonthFormatWithoutYearUI = 'HH:mm do MMMM';
export const dateShortMonthFormatUI = 'dd MMM yyyy';

export const defaultCurrency = { code: 'USD', symbol: '$' };
