import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { FeatureToggleName } from './feature-toggle.interface';
import { FeatureService } from './feature.service';

@Directive({
  selector: '[feature]',
})
export class FeatureDirective implements OnInit {
  @Input() feature: FeatureToggleName;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private featureTogglesService: FeatureService
  ) {}

  ngOnInit(): void {
    if (this.featureTogglesService.isEnabled(this.feature)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
