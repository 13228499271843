export function contactSortFields<T>(controls: Array<T>, sortingDictionaries: string[]): Array<T> {
  if (!controls?.length) {
    return [];
  }
  const sortingControls: Array<T> = [...controls];
  return sortingControls.sort((obj: any, obj2: any) => {
    if (sortingDictionaries.indexOf(obj.control) > sortingDictionaries.indexOf(obj2.control)) {
      return 1;
    }
    if (sortingDictionaries.indexOf(obj.control) < sortingDictionaries.indexOf(obj2.control)) {
      return -1;
    }
    return 0;
  });
}
