export enum InvoiceStatusEnum {
  WAITING_TO_BE_INVOICED = 1,
  DRAFT_INVOICE = 2,
  SENT_TO_CONSOLE_INVOICE = 3,
  SENT_TO_SAP_INVOICE = 4,
  WAITING_TO_BE_PAID = 5,
  SENT_TO_CLIENT = 6,
  PAID_INVOICE = 7,
  CLOSED = 8,
  SENT = 9,
  WAITING_FOR_PO_UPDATE = 10,
  SENT_TO_PRS = 11,
}
