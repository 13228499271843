import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { selectFullPagePending } from '@core/store/core.selectors';

@Component({
  selector: 'app-content-container',
  templateUrl: './app-content-container.component.html',
  styleUrls: ['./app-content-container.component.scss'],
})
export class AppContentContainerComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  public selectFullPagePending$ = this.store.select(selectFullPagePending);

  constructor(private route: ActivatedRoute, private store: Store) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
