import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { PermissionsEnum } from '@shared/enums/permissions.enum';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { UserTypeEnum } from '@shared/enums/user-type.enum';
import { StudioSite } from '@shared/models';
import { StudiositePermission } from '@shared/models/auth/studiosite-permission.model';

import { UserModel } from '@shared/models/auth/user.model';
import { Observable, of, zip } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { getUser, selectActiveStudioSiteId, selectStudioSites } from '../store/auth.selectors';

@Injectable()
export class UserPermissionGuard {
  constructor(private store: Store, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const path: string = state.url;
    const permissionSlug = route.data.permissionSlug;

    return this.store.pipe(
      select(selectActiveStudioSiteId),
      switchMap((activeStudioSiteId: number | null) =>
        zip(
          of(activeStudioSiteId),
          this.store.pipe(select(getUser)),
          this.store.pipe(
            select(selectStudioSites),
            filter((list) => !!list)
          )
        )
      ),
      map(([studioSiteId, user, studioSites]: [number | null, UserModel | null, StudioSite[]]) => {
        if (!user) {
          return false;
        }

        if ((user.userType === UserTypeEnum.schedule && !path.startsWith('/studios')) || !user.permissions) {
          this.router.navigate(['/studios']);
          return false;
        }

        // we get ss id from URL first and only then we try to use one from store
        const pathParts: string[] = path.split('/');
        const pathSsid = +(pathParts[0]?.length ? pathParts[0] : pathParts[1]);
        let ssid = studioSiteId;

        if (!!pathSsid && ((!!studioSiteId && studioSiteId !== pathSsid) || !studioSiteId)) {
          ssid = pathSsid;
        }

        if (!ssid || !studioSites.find((ss: StudioSite) => ss.studioSiteId === ssid)) {
          this.router.navigate(['/studios']);
          return false;
        }

        const permissionBySs: StudiositePermission | null = user.permissions[ssid] ?? null;
        if (!permissionBySs) {
          this.router.navigate(['/studios']);
          return false;
        }

        const isAr = permissionBySs[PermissionsEnum.isAr] ?? false;
        const isScheduleUser = permissionBySs[PermissionsEnum.isScheduleUser] ?? false;
        if (isAr || isScheduleUser) {
          if (path.startsWith(`/${ssid}${RoutesEnum.schedule}`)) {
            return true;
          }
          this.router.navigate([`/${ssid}${RoutesEnum.schedule}`]);
          return false;
        }

        if (!permissionBySs[permissionSlug as keyof StudiositePermission]) {
          this.router.navigate([this.getNextAvailablePath(user.permissions[ssid], ssid)]);
          return false;
        }

        return true;
      }),
      take(1)
    );
  }

  private getNextAvailablePath(permissions: StudiositePermission, studioSiteId: number): string {
    if (permissions[PermissionsEnum.menuBooking]) {
      return `/${studioSiteId}${RoutesEnum.bookings}`;
    }
    if (permissions[PermissionsEnum.menuSchedule]) {
      return `/${studioSiteId}${RoutesEnum.schedule}`;
    }
    if (permissions[PermissionsEnum.menuSessionSheets]) {
      return `/${studioSiteId}${RoutesEnum.sessionSheets}`;
    }
    if (permissions[PermissionsEnum.menuInvoices]) {
      return `/${studioSiteId}${RoutesEnum.invoices}`;
    }
    if (permissions[PermissionsEnum.menuPurchaseOrders]) {
      return `/${studioSiteId}${RoutesEnum.purchaseOrders}`;
    }
    if (permissions[PermissionsEnum.menuContacts] && permissions[PermissionsEnum.contactsAddressBook]) {
      return `/${studioSiteId}${RoutesEnum.contacts}`;
    }
    if (permissions[PermissionsEnum.menuSettings]) {
      return `/${studioSiteId}${RoutesEnum.settings}`;
    }
    if (permissions[PermissionsEnum.menuReports]) {
      return `/${studioSiteId}${RoutesEnum.reports}`;
    }
    return '/studios';
  }
}
