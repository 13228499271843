import { createAction, props } from '@ngrx/store';

import { ArtistGRPSContact } from '@shared/modules/contacts/models/artist-contacts-grps.model';

import { Artist } from '@shared/modules/contacts/models';

export const cancelLoadContactsArtistRequest = createAction('[ContactsArtist/UI] Cancel Load Contacts Artist');

export const loadContactsArtistRequest = createAction(
  '[ContactsArtist/API] Load Contacts Artist',
  props<{ artistKey: any }>()
);
export const loadContactsArtistRequestSuccess = createAction(
  '[ContactsArtist/API] Load Contacts Artist Success',
  props<{ data: Artist }>()
);
export const loadContactsArtistRequestFailure = createAction(
  '[ContactsArtist/API] Load Contacts Artist Failure',
  props<{ error?: any }>()
);

export const createContactsArtistRequest = createAction(
  '[ContactsArtist/API] Create Contacts Artist',
  props<{ artist: Artist }>()
);
export const createContactsArtistRequestSuccess = createAction(
  '[ContactsArtist/API] Create Contacts Artist Success',
  props<{ data: Artist }>()
);
export const createContactsArtistRequestFailure = createAction(
  '[ContactsArtist/API] Create Contacts Artist Failure',
  props<{ error: any }>()
);

export const updateContactsArtistRequest = createAction(
  '[ContactsArtist/API] Update Contacts Artist',
  props<{ artist: Artist }>()
);
export const updateContactsArtistRequestSuccess = createAction(
  '[ContactsArtist/API] Update Contacts Artist Success',
  props<{ data: Artist }>()
);
export const updateContactsArtistRequestFailure = createAction(
  '[ContactsArtist/API] Update Contacts Artist Failure',
  props<{ error: any }>()
);

export const updateActiveArtist = createAction(
  '[ContactsArtist/UI] Update active artist object in store',
  props<{ data: Artist | null }>()
);
export const clearActiveArtist = createAction('[ContactsArtist/UI] Clear Active Artist in Store');

export const loadGRPSArtistsRequest = createAction('[ContactsArtist/API] Load GRPS Artists', props<{ name: string }>());
export const loadGRPSArtistsRequestSuccess = createAction(
  '[ContactsArtist/API] Load GRPS Artists Success',
  props<{ data: ArtistGRPSContact[] }>()
);
export const loadGRPSArtistsRequestFailure = createAction(
  '[ContactsArtist/API] Load GRPS Artists Failure',
  props<{ error: any }>()
);
