import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as AuthActions from '@auth/store/auth.actions';
import { StudioSite } from '@shared/models';
import { selectActiveStudioSite, selectStudioSites } from '@auth/store/auth.selectors';

@Component({
  selector: 'app-studiosite-nav',
  templateUrl: './app-studiosite-nav.component.html',
  styleUrls: ['./app-studiosite-nav.component.scss'],
})
export class AppStudiositeNavComponent implements OnInit {
  @Input() darkMode = false;

  public channels$: Observable<StudioSite[]> = this.store.select(selectStudioSites);
  public activeStudiosite$: Observable<StudioSite | null> =
    this.store.select(selectActiveStudioSite);

  constructor(private store: Store) {}

  ngOnInit(): void {}

  public getChannelInitials(channel: StudioSite): string {
    return channel.studioSiteName
      .split(' ')
      .map((n: string, index) => {
        return index < 2 ? n[0] : '';
      })
      .join('');
  }

  public selectChannel(channel: StudioSite): void {
    this.store.dispatch(
      AuthActions.setActiveStudioSite({ studioSite: channel.studioSiteId, redirectToSs: true })
    );
  }

  public goHome(): void {
    this.store.dispatch(AuthActions.setActiveStudioSite({ studioSite: null, redirectToSs: true }));
  }
}
