import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as AuthActions from '../../auth/store/auth.actions';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  sessionExpiredTimer: any;

  constructor(private store: Store<any>) {}

  tokenLifeTime(auth: any): void {
    const lifeTime = Date.now() + auth.lifetime * 1000;
    this.isTokenAlive(lifeTime);
    this.clearSessionTimer();
    this.initTimer(lifeTime);
  }

  clearSessionTimer(): void {
    clearInterval(this.sessionExpiredTimer);
  }

  private initTimer(lifeTime: number): void {
    this.sessionExpiredTimer = setInterval(() => {
      this.isTokenAlive(lifeTime);
    }, 5000);
  }

  private isTokenAlive(lifeTime: number): void {
    if (Date.now() > lifeTime) {
      this.clearSessionTimer();
      this.store.dispatch(
        AuthActions.logout({ withRequest: true, source: 'lifetime check', message: 'token expired' })
      );
    }
  }
}
