<div class="text-center d-flex flex-column">
  <h1>{{ "AUTH_MODULE.LOGOUT_TITLE" | translate }}</h1>
  <h4>{{ "AUTH_MODULE.LOGOUT_QUESTION" | translate }}</h4>

  <div class="form-group d-flex justify-content-center">
    <a
      mat-raised-button
      color="primary"
      routerLink="/login"
      >{{ "AUTH_MODULE.LOGIN_AGAIN" | translate }}</a
    >
  </div>
</div>
