<div class="d-flex flex-column w-100">
  <label
    class="sc-label"
    [class]="labelClasses"
    [ngClass]="{ 'disabled-label': disabled }"
    *ngIf="label.length"
  >
    {{ label }}
    <span
      *ngIf="(ngControl?.validator && (showStarRequired === true || showStarRequired === undefined)) || showStarAlways"
      class="error-label"
      [class.input-error-message]="!ngControl?.valid && ngControl?.touched && !ngControl?.disabled"
      >*</span
    >
  </label>
  <label
    [class]="subLabelClasses"
    *ngIf="subLabel.length"
  >
    {{ subLabel }}
  </label>
  <div
    class="sc-input-wrapper blue position-relative overflow-hidden d-flex"
    (click)="registerClickEvent($event)"
    [ngClass]="{
      focus: hasFocus,
      disabled: ngControl?.disabled
    }"
  >
    <div
      *ngIf="type === 'currency'"
      class="flex-shrink-0 justify-content-center currency-ico d-flex align-items-center fs-16 fw-medium"
    >
      <span>{{ icon }}</span>
    </div>

    <input
      class="sc-input"
      #data
      autocomplete="new-password"
      [style]="{ borderRadius: borderRadius }"
      [disabled]="disabled"
      [value]="value"
      [class]="inputClasses"
      [ngClass]="{
        'ng-invalid': isInvalidControl(),
        'ng-valid': ngControl?.valid,
        'ng-touched': ngControl?.touched,
        'ng-untouched': !ngControl?.touched,
        disabled: ngControl?.disabled,
        'with-icon': hasInputIcon()
      }"
      [placeholder]="placeholder"
      [type]="type === 'currency' ? 'text' : type"
      (blur)="markAsTouched(); blurEvent.emit()"
      (input)="input($event)"
      (keydown)="keyDown($event)"
      (focus)="registerFocusEvent($event)"
      (wheel)="onWheel()"
    />
    <img
      *ngIf="hasInputIcon()"
      class="input-icon pointer {{ iconClass }}"
      [src]="icon"
      alt=""
    />
  </div>
  <span
    *ngIf="!ngControl?.valid && ngControl?.touched && !ngControl?.disabled"
    class="fs-12 lh-18 input-error-message"
  >
    {{ validationMessage }}
  </span>
</div>
