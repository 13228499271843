import { IStudiositePreference } from '@shared/models/auth/studiosite-preference.interface';

export class StudiositePreference {
  public studioContactId: number;
  public isAr: boolean;

  constructor(raw?: IStudiositePreference) {
    if (!raw) {
      return;
    }

    this.studioContactId = raw.studio_contact_id;
    this.isAr = raw.is_ar || false;
  }
}
