import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as clone from 'rfdc';
import { IApiResponse } from '@shared/models';

const cloneDeepRef = clone({ circles: true });

export const cloneDeep = (obj: any) => {
  return cloneDeepRef(obj);
};

export function isNumber(value: any): boolean {
  return typeof value === 'number';
}

export function isString(value: any): boolean {
  const type = typeof value;
  return (
    type === 'string' ||
    (type === 'object' && value != null && !Array.isArray(value) && toString.call(value) == '[object String]')
  );
}

export function isObject(value: any): boolean {
  const type = typeof value;
  return value != null && (type === 'object' || type === 'function');
}

export function groupBy<T = unknown>(array: T[], key: string): Record<string | number, T[]> {
  return array.reduce((acc, item) => {
    // @ts-ignore
    (acc[item[key]] = acc[item[key]] || []).push(item);
    return acc;
  }, {});
}

export const omit = (obj: any, blacklistedKeys: Array<any>) => {
  Object.keys(obj)
    .filter((key) => blacklistedKeys.indexOf(key) < 0)
    .reduce((newObj, key) => Object.assign(newObj, { [key]: obj[key] }), {});
};

export const clearExtraSpace = (str: string, addDots = false): string | any => {
  if (!str) {
    return undefined;
  }
  const nStr = str.trim().replace(/\s+/g, ' ');
  return addDots ? nStr.split(' ').join('... ') : nStr;
};

export const insert = (arr: any[], index: number, newItem: any) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
];

export const flatten = (obj: any) => {
  const array = Array.isArray(obj) ? obj : [obj];
  return array.reduce(function (acc, value) {
    acc.push(value);
    if (value.children) {
      acc = acc.concat(flatten(value.children));
      delete value.children;
    }
    return acc;
  }, []);
};

/////////////////
// Template error Type 'AbstractControl' is not assignable to type 'FormControl'
/////////////////
// the same bug that I mentioned -> this.form.get('headerGroup') return type AbstractControl | null
// but we expect in the PersonFormHeaderComponent a FormGroup as headerGroup: this.fb.group
export const asFormGroup = (ctrl: AbstractControl | null): UntypedFormGroup | any => {
  if (!ctrl) {
    return null;
  }
  return ctrl as UntypedFormGroup;
};

export const asFormControl = (ctrl: AbstractControl | null): UntypedFormControl | any => {
  if (!ctrl) {
    return null;
  }
  return ctrl as UntypedFormControl;
};

export const asFormArray = (ctrl: AbstractControl | null): UntypedFormArray | any => {
  if (!ctrl) {
    return null;
  }
  return ctrl as UntypedFormArray;
};

export const isSameString = (original: string | null, comparable: string | null): boolean => {
  const trimmedOriginal = (original || '').toLowerCase().trim();
  const trimmedComparable = (comparable || '').toLowerCase().trim();
  return !!(trimmedOriginal && trimmedComparable && trimmedOriginal === trimmedComparable);
};

export const handleClassForElement = <T extends string>(
  blocks: (Element | null)[],
  classNameSet: T,
  classNameRemove?: T
): void | null => {
  if (!blocks?.length || !classNameSet) {
    return null;
  }

  blocks.map((item: Element | null) => {
    if (!item) {
      return;
    }
    if (classNameRemove) {
      item.classList.remove(classNameRemove);
    }
    item.classList.add(classNameSet);
  });
};

export const getDefaultApiResponse = <T>(): IApiResponse<T> => {
  return {
    data: { rows: [] },
    meta: { code: 200, message: '' },
  };
};

export const bytesToSize = (bytes: number): string => {
  const sizes = ['b', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 b';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
};
