import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';

import { Store } from '@ngrx/store';

import { takeUntil } from 'rxjs/operators';

import { AppConfigService } from '../../shared/services/app-config.service';
import { UserService } from '../../core/service/user.service';
import * as AuthActions from '../store/auth.actions';

import { AuthService } from './auth.service';

// declare var USMALIVE: any;

/**
 * OktaSession service
 * Provides means for okta session regulation
 */
@Injectable({
  providedIn: 'root',
})
export class OktaSessionService {
  public unsubscribe$ = new Subject<void>();

  private userActivitySubscription: any;
  private oktaSessionInterval: any;
  private oktaSessionTimeout: any;
  private lastActivityTime = Date.now();
  private activated: boolean;
  private token = '';

  readonly PING_INTERVAL = 20000; // 20 seconds?

  readonly events = {
    userIsActive: new Subject<number>(),
  };

  constructor(
    private userService: UserService,
    private appConfigService: AppConfigService,
    private store: Store<any>,
    private authService: AuthService
  ) {}

  start(lifetime: number): void {
    this.activated = this.appConfigService.isUSMAuth;
    if (this.activated) {
      this.token = JSON.parse(localStorage.getItem('auth') as string)?.user.token;
      if (!this.token) {
        return;
      }

      this.userActivitySubscription = this.events.userIsActive.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        this.lastActivityTime = Date.now();
      });

      this.oktaSessionInterval = setInterval(() => {
        if (Date.now() - this.lastActivityTime < this.PING_INTERVAL) {
          this.store.dispatch(AuthActions.pingUSM({ token: this.token }));
          if (this.oktaSessionTimeout) {
            clearTimeout(this.oktaSessionTimeout);
          }
          this.oktaSessionTimeout = setTimeout(() => {
            this.stop();
            // this.store.dispatch(AuthActions.logout({withRequest: true}));
          }, lifetime);
        }
      }, this.PING_INTERVAL);
    }
  }

  stop(): void {
    if (this.activated) {
      if (this.userActivitySubscription) {
        this.userActivitySubscription.unsubscribe();
      }
      clearInterval(this.oktaSessionInterval);
      clearTimeout(this.oktaSessionTimeout);
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
    }
  }

  userIsActive(): void {
    this.events.userIsActive.next(Date.now());
  }
}
