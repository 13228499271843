<button
  mat-button
  tabIndex="-1"
  [disabled]="disabled"
  [type]="type"
  [ngClass]="getBtnClasses()"
  (click)="clickEvent.emit($event)"
>
  <mat-icon *ngIf="matIconName && !img?.url">
    {{ matIconName }}
  </mat-icon>
  <img
    *ngIf="!matIconName && img?.url"
    [src]="img.url"
    [style.height]="img.height || ''"
    [style.width]="img.width || ''"
    alt=""
  />

  <ng-template [ngTemplateOutlet]="labelTemplate || defaultLabelTemplate"> </ng-template>

  <ng-template #defaultLabelTemplate>
    <span
      class="d-inline-flex align-items-center justify-content-center"
      [innerHTML]="label"
    ></span>
  </ng-template>
</button>
<span [class.spinner]="pending"></span>
