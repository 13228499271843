<section class="d-inline-flex align-items-stretch">
  <mat-button-toggle-group
    *ngIf="buttonToggleGroup?.length"
    [formControl]="buttonToggleFormControl"
    [class.no-brl]="noBorderRadius === 'left'"
    [class.no-brr]="noBorderRadius === 'right'"
  >
    <mat-button-toggle
      *ngFor="let item of buttonToggleGroup; let i = index"
      [disabled]="item.disabled || false"
      [checked]="i === 0"
      [value]="item.value"
      [matTooltipDisabled]="!item.tooltipText"
      matTooltipClass="app-button-toggle-group-tooltip tooltip-below tooltip-shape"
      matTooltipShowDelay="1000"
      [matTooltipPosition]="'below'"
      [matTooltip]="item.tooltipText || ''"
    >
      <ng-container *ngIf="item.icon; else name">
        <mat-icon [svgIcon]="item.icon"></mat-icon>
      </ng-container>

      <ng-template #name>
        {{ item.name }}
      </ng-template>
    </mat-button-toggle>
  </mat-button-toggle-group>
</section>
