import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: 'app-card.component.html',
  styleUrls: ['app-card.component.scss'],
})
export class AppCardComponent {
  @Input()
  type: 'embossing' | 'primary' | 'shadow-none' | 'transparent' = 'primary';

  @Input() loading = false;

  @Input()
  hasHover = false;

  @Input()
  clases = '';

  public get classes(): string {
    return `card card-${this.type} ${this.clases}`;
  }
}
