export function companyTypeMapper(type: string | undefined | null, excludeSony?: boolean): string {
  switch (type) {
    case 'A': {
      return excludeSony ? 'Affiliate Company' : 'Sony Music Affiliate Company';
    }
    case 'H': {
      return excludeSony ? 'Home Company' : 'Sony Music Home Company';
    }
    case 'C': {
      return excludeSony ? 'Corp Company' : 'Sony Corp Company';
    }
    case '3': {
      return 'Third-party Company';
    }
    default:
      return '';
  }
}
