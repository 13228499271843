import { Directive, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { merge } from 'rxjs/operators';

/**
 * Inactivity directive
 */
@Directive({
  selector: '[ngxInactivity]',
})
export class NgxInactivityDirective {
  private lastTime = Date.now();
  /**
   * Mouse move event emitter
   */
  private mousemove = new EventEmitter();

  /**
   * Mouse down event emitter
   */
  private mousedown = new EventEmitter();

  /**
   * Keypress event emitter
   */
  private keypress = new EventEmitter();

  @Input() public interval = 3000;

  /**
   * Inactivity callback after timeout
   */
  @Output() ngxInactivityCallback = new EventEmitter();

  /**
   * Attach a mouse move listener
   */
  @HostListener('document:mousemove', ['$event'])
  onMousemove(event: any): void {
    this.mousemove.emit(event);
  }

  /**
   * Atach a mouse down listener
   */
  @HostListener('document:mousedown', ['$event'])
  onMousedown(event: any): void {
    this.mousedown.emit(event);
  }

  /**
   * Attach a key press listener
   */
  @HostListener('document:keypress', ['$event'])
  onKeypress(event: any): void {
    this.keypress.emit(event);
  }

  constructor() {
    /*
     * Merge to flattens multiple Observables together
     * by blending their values into one Observable
     */
    this.mousemove
      .pipe(merge(this.mousedown, this.keypress))
      /*
       * Subscribe to handle emitted values
       */
      .subscribe(() => {
        if (Date.now() - this.lastTime > this.interval) {
          this.lastTime = Date.now();
          this.ngxInactivityCallback.emit(true);
        }
      });
  }
}
