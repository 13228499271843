import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

interface StoreItem {
  id: string;
  url: string;
  method: string;
}

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loadingStore: StoreItem[] = [];
  private loadingSubject = new BehaviorSubject<StoreItem[]>([]);

  readonly loading$ = this.loadingSubject
    .asObservable()
    .pipe(map((loadingStore) => loadingStore.length > 0));

  setLoading(loading: boolean, url: string, method: string, id: string): void {
    if (loading) {
      this.loadingStore.push({ id, url, method });
    } else {
      this.loadingStore = this.loadingStore.filter((item) => item.id !== id);
    }

    this.loadingSubject.next(this.loadingStore);
  }
}
