import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from '@core/service/user.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { LocalStorageEnum } from '@shared/enums/local-storage.enum';
import { IUserAuth } from '@shared/models/auth/user-auth.interface';
import { UserModel } from '@shared/models/auth/user.model';
import { AppConfigService } from '@shared/services/app-config.service';
import { ToasterService } from '@shared/services/toaster.service';
import { getLastPage } from '@shared/utils/last-page-history.utils';
import { EMPTY, of, zip } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { IStudioSite } from '@shared/models/auth/studiosite.interface';

import { StudioSite } from '@shared/models/auth/studiosite.model';

import { LogoutDialogComponent } from '../components/logout-dialog/logout-dialog.component';

import { AuthService } from '../services/auth.service';

import { OktaSessionService } from '../services/okta-session.service';

import * as AuthActions from './auth.actions';
import { SchedulerBannerStorageService } from '@shared/modules/scheduler/services/scheduler-banner-storage.service';
import {
  getUser,
  getUserToken,
  isAuthUSM,
  selectActiveStudioSite,
  selectActiveStudioSiteId,
  selectStudioSites,
} from './auth.selectors';
import { areObjectsEqual, sortNumberArrayFn } from '@shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { LsLogService } from '@core/service/ls-log.service';

@Injectable()
export class AuthEffects {
  constructor(
    private router: Router,
    private actions$: Actions,
    private authService: AuthService,
    private appConfigService: AppConfigService,
    private dialog: MatDialog,
    private userService: UserService,
    private toasterService: ToasterService,
    private store: Store<any>,
    private oktaService: OktaSessionService,
    private schedulerBannerStorage: SchedulerBannerStorageService,
    private translateService: TranslateService,
    private lsLogService: LsLogService
  ) {}

  // ---------- Login via Login + Password ----------
  loginClassic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginClassic),
      exhaustMap((action: { username: string; password: string }) =>
        this.authService.login(action.username, action.password).pipe(
          map((resp: { data: IUserAuth; status: any }) => {
            this.store.dispatch(AuthActions.updateTokenInStore({ token: resp.data.token }));
            if (localStorage.getItem(LocalStorageEnum.LastActiveStudioSite)?.length) {
              this.store.dispatch(
                AuthActions.setActiveStudioSite({
                  studioSite: +localStorage.getItem(LocalStorageEnum.LastActiveStudioSite)!,
                  redirectToSs: true,
                })
              );
            }
            return AuthActions.loadUserInfo({ token: resp.data.token });
          }),
          catchError(({ error }) => of(AuthActions.loginFailure(error)))
        )
      )
    )
  );
  // ------------------------------------------------

  // ---------- Login via USM -----------------------
  loginUSM$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loginUSM),
        map(() => {
          window.location.href = this.authService.loginUSM();
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  exchangeToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.exchangeToken),
      switchMap((action: { exchange_token: string }) => this.authService.exchangeToken(action.exchange_token)),
      switchMap((token: string) => {
        this.store.dispatch(AuthActions.updateTokenInStore({ token }));
        if (localStorage.getItem(LocalStorageEnum.LastActiveStudioSite)?.length) {
          this.store.dispatch(
            AuthActions.setActiveStudioSite({
              studioSite: +localStorage.getItem(LocalStorageEnum.LastActiveStudioSite)!,
              redirectToSs: true,
            })
          );
        }
        return of(AuthActions.loadUserInfo({ token }));
      }),
      catchError(({ error }) => {
        return of(
          AuthActions.loginFailure(error),
          AuthActions.logout({ withRequest: true, source: 'exchangeToken', message: 'exchangeToken failed' })
        );
      })
    )
  );
  // ------------------------------------------------

  loadUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loadUserInfo),
      switchMap((action: { token: string; isSimpleRefresh?: boolean; redirectToStudioSiteId?: number }) =>
        zip(this.authService.retrieveUser(action.token), of(action))
      ),
      exhaustMap(
        ([userInfo, action]: [
          { data: IUserAuth; status: any },
          { token: string; isSimpleRefresh?: boolean; redirectToStudioSiteId?: number }
        ]) => {
          const preferences = this.authService.parsePreferences(userInfo.data?.preferences);
          const permissions =
            this.authService.parsePermissions(userInfo.data?.studiosite_permission_flags) || undefined;
          const userType = this.authService.getUserType(permissions);
          const user: UserModel = new UserModel({ ...userInfo.data }, preferences, permissions, userType);
          return of(
            AuthActions.loginSuccess({
              user,
              isSimpleRefresh: action.isSimpleRefresh,
              redirectToStudioSiteId: action.redirectToStudioSiteId,
            })
          );
        }
      ),
      catchError(({ error }) => of(AuthActions.loginFailure(error)))
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        map((action: { user: UserModel; isSimpleRefresh?: boolean; redirectToStudioSiteId?: number }) =>
          this.store.dispatch(
            AuthActions.loadStudioSites({
              isSimpleRefresh: action.isSimpleRefresh,
              redirectToStudioSiteId: action.redirectToStudioSiteId,
            })
          )
        )
      ),
    { dispatch: false }
  );

  loadStudioSites$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loadStudioSites),
        switchMap(
          (action: { isSimpleRefresh?: boolean; redirectToStudioSiteId?: number; refreshStudioSiteId?: number }) =>
            zip(of(action), this.authService.getStudioSites(), this.store.pipe(select(getUser)))
        ),
        tap(
          ([action, studioSites, user]: [
            { isSimpleRefresh?: boolean; redirectToStudioSiteId?: number; refreshStudioSiteId?: number },
            { data: { rows: IStudioSite[] }; meta: any },
            UserModel | null
          ]) => {
            this.store.dispatch(
              AuthActions.studioSitesLoadSuccess({
                studioSites: studioSites.data.rows
                  ?.filter((raw: IStudioSite) => !!user?.permissions && !!user.permissions[raw.studiosite])
                  ?.map((raw: IStudioSite) => new StudioSite(raw)),
              })
            );
            if (!!action.redirectToStudioSiteId) {
              this.store.dispatch(
                AuthActions.setActiveStudioSite({
                  studioSite: action.redirectToStudioSiteId!,
                  redirectToSs: true,
                })
              );
              return;
            }

            if (!!action.refreshStudioSiteId) {
              this.store.dispatch(
                AuthActions.setActiveStudioSite({
                  studioSite: action.refreshStudioSiteId,
                })
              );
              return;
            }

            if (!action.isSimpleRefresh) {
              this.store.dispatch(AuthActions.navigateToAvailableStudioSite({}));
            }
          }
        )
      ),
    { dispatch: false }
  );

  navigateToAvailableStudioSite$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.navigateToAvailableStudioSite),
        mergeMap((action: { withPageReload?: boolean }) =>
          zip(
            of(action),
            this.store.pipe(select(selectStudioSites), take(1)),
            this.store.pipe(select(selectActiveStudioSiteId))
          )
        ),
        exhaustMap(
          ([action, studioSites, activeStudioSiteId]: [{ withPageReload?: boolean }, StudioSite[], number]) => {
            const returnUrl: string | null = localStorage.getItem('returnUrl');
            const startUrl: string | null = localStorage.getItem('startUrl');
            let ssid: number = activeStudioSiteId;

            const ssidFromPath = (path: string, defaultValue: number): number => {
              const pathParts: string[] = path.split('/');
              const possibleSsid = pathParts.find((p: string) => !!+p);
              return possibleSsid ? +possibleSsid : defaultValue;
            };

            // all related to permissions and UserType checks is done in Permission Guard

            if (returnUrl) {
              localStorage.removeItem('returnUrl');
              localStorage.removeItem('startUrl');
              ssid = ssidFromPath(returnUrl, activeStudioSiteId);

              if (ssid && studioSites?.find((ss) => ss.studioSiteId === ssid)) {
                this.store.dispatch(AuthActions.setActiveStudioSite({ studioSite: ssid }));
                this.store.dispatch(AuthActions.setUserContactInfo());
                return this.router.navigate([returnUrl]);
              }
            }

            if (startUrl) {
              if (startUrl.startsWith('/studios') || startUrl.startsWith('/settings')) {
                this.store.dispatch(AuthActions.setUserContactInfo());
                this.store.dispatch(AuthActions.setActiveStudioSite({ studioSite: 0 }));
                if (this.router.routerState.snapshot.url === '/login') {
                  return this.router.navigate(['/studios']);
                }
                return EMPTY;
              }

              localStorage.removeItem('startUrl');
              ssid = ssidFromPath(startUrl, activeStudioSiteId);

              if (ssid && studioSites?.find((ss) => ss.studioSiteId === ssid)) {
                this.store.dispatch(AuthActions.setActiveStudioSite({ studioSite: ssid }));
                this.store.dispatch(AuthActions.setUserContactInfo());
                return startUrl.indexOf('/login') >= 0 ? this.router.navigate([`/${ssid}`]) : EMPTY;
              }
            }

            ssid =
              activeStudioSiteId && studioSites?.find((ss) => ss.studioSiteId === activeStudioSiteId)
                ? activeStudioSiteId
                : 0;

            if (ssid) {
              this.store.dispatch(AuthActions.setActiveStudioSite({ studioSite: ssid }));
              this.store.dispatch(AuthActions.setUserContactInfo());
              // We need it to reload page and force 'canActivate' to fire again.
              // This is required for proper redirection on permissions change.
              if (action.withPageReload) {
                window.location.reload();
              }
              return this.router.navigate([`/${ssid}`]);
            }

            this.store.dispatch(AuthActions.setActiveStudioSite({ studioSite: 0 }));
            return this.router.navigate(['/studios']);
          }
        )
      ),
    { dispatch: false }
  );

  setActiveStudioSite$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.setActiveStudioSite),
        tap((action: { studioSite: number | null; redirectToSs?: boolean }) => {
          if (!action.redirectToSs) {
            return EMPTY;
          }

          if (!action.studioSite) {
            return this.router.navigate(['/studios']);
          }

          this.store.dispatch(AuthActions.setUserContactInfo());

          const lastPage = getLastPage(action.studioSite);

          return this.router.navigate([lastPage ?? `/${action.studioSite}/bookings/list/all`]);
        })
      ),
    { dispatch: false }
  );

  setUserContactInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.setUserContactInfo),
      mergeMap(() => zip(this.store.pipe(select(getUser)), this.store.pipe(select(selectActiveStudioSite)))),
      exhaustMap(([user, activeStudioSite]: [UserModel | null, StudioSite]) => {
        if (!user || !activeStudioSite?.studioSiteId) {
          return EMPTY;
        }
        let userAddField: any = {
          data: {
            userRoles: [
              {
                role_name: activeStudioSite.roleName ?? this.translateService.instant('AUTH_MODULE.GUEST'),
              },
            ],
          },
        };
        return of(AuthActions.userAddField(userAddField));
      })
    )
  );

  loginRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loginRedirect),
        tap((data) => {
          this.lsLogService.addLog(data.source, data.message);
          return this.router.navigate(['/login']);
        })
      ),
    { dispatch: false }
  );

  loginFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loginFailure),
        tap(() => {
          this.toasterService.error('Login failure');
        })
      ),
    { dispatch: false }
  );

  // ---------- Logout ------------------------------

  logoutConfirmation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logoutConfirmation),
        exhaustMap(() => {
          const dialogRef = this.dialog.open<LogoutDialogComponent>(LogoutDialogComponent);
          return dialogRef.afterClosed();
        }),
        map((result) =>
          result
            ? this.store.dispatch(
                AuthActions.logout({ withRequest: true, source: 'user action', message: 'user pressed Log Out' })
              )
            : EMPTY
        )
      ),
    { dispatch: false }
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      switchMap((data) => zip(of(data), this.store.pipe(select(isAuthUSM)), this.store.pipe(select(getUser)))),
      exhaustMap(
        ([data, isUSM, user]: [
          { withRequest: boolean; source: string; message: string },
          boolean,
          UserModel | null
        ]) => {
          sessionStorage.clear();
          this.schedulerBannerStorage.clear();

          this.lsLogService.addLog(data.source, data.message);

          if (!data.withRequest) {
            this.store.dispatch(AuthActions.logoutSuccess());
            this.store.dispatch(AuthActions.loginRedirect({ source: data.source, message: data.message }));
            return EMPTY;
          }

          if (isUSM) {
            this.store.dispatch(AuthActions.clearUser());
            this.userService.clearSessionTimer();
            window.location.href = this.authService.logoutUSM(user);
            return EMPTY;
          }

          return this.authService.logout().pipe(
            map(() => AuthActions.logoutSuccess()),
            catchError(({ error }) => of(AuthActions.logoutFailure(error)))
          );
        }
      )
    )
  );

  logoutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logoutSuccess, AuthActions.logoutFailure),
        tap(() => {
          this.dialog.closeAll();
          this.userService.clearSessionTimer();
          localStorage.removeItem(LocalStorageEnum.LastPageStudioSite);
          localStorage.removeItem(LocalStorageEnum.SearchConditions);
          return this.router.navigate(['/logout']);
        })
      ),
    { dispatch: false }
  );

  // ------------------------------------------------

  userTokenExpired$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.userTokenExpired),
        tap(() => {
          if (localStorage.getItem('auth')?.length) {
            localStorage.removeItem('auth');
            this.userService.clearSessionTimer();
            this.store.dispatch(
              AuthActions.loginRedirect({ source: 'userTokenExpired', message: 'user token expired' })
            );
          }
        })
      ),
    { dispatch: false }
  );

  userTokenLifetime$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.userTokenLifetime),
        tap((auth) => {
          this.userService.tokenLifeTime(auth);
        })
      ),
    { dispatch: false }
  );

  pingUSM$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.pingUSM),
      switchMap(({ token }) => {
        return this.authService.pingUSM(token).pipe(
          map((res: { data: IUserAuth; status: any }) => AuthActions.pingUSMSuccess({ data: res.data })),
          catchError((error) => {
            this.oktaService.stop();
            return of(AuthActions.pingUSMFailure({ error }));
          })
        );
      })
    )
  );

  pingUSMSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.pingUSMSuccess),
        switchMap((action) =>
          zip(of(action.data), this.store.pipe(select(getUser)), this.store.pipe(select(selectActiveStudioSite)))
        ),
        exhaustMap(([data, user, activeStudioSite]: [IUserAuth, UserModel | null, StudioSite]) => {
          // Just in case if something wrong in a beginning
          if (!user) {
            this.store.dispatch(
              AuthActions.logout({ withRequest: true, source: 'ping', message: 'user is not logged in' })
            );
          }

          // permissions from PING request
          const permissions = this.authService.parsePermissions(data.studiosite_permission_flags);
          const userType = this.authService.getUserType(permissions || undefined);

          // If user looses all permissions - set those empty permissions and redirect to Home page
          if (!permissions && activeStudioSite.studioSiteId !== 0) {
            this.store.dispatch(
              AuthActions.loginSuccess({
                user: { ...user!, permissions, userType },
                isSimpleRefresh: true,
              })
            );
            this.dialog.closeAll();
            this.store.dispatch(AuthActions.setActiveStudioSite({ studioSite: 0 }));
            this.router.navigate(['/studios']);
            return EMPTY;
          }

          // Get list of studiosites where user had a permissions and studiosites with permissions from PING request.
          // Turn into number arrays and sort them.
          const currentSsList = Object.keys(user?.permissions || {})
            .map((i) => +i)
            .sort(sortNumberArrayFn);
          const newSsList = Object.keys(permissions || {})
            .map((i) => +i)
            .sort(sortNumberArrayFn);

          const isPermissionsLengthDiffer = currentSsList.length !== newSsList.length;
          const isPermissionsElementsDiffer = currentSsList.some((value, index) => value !== newSsList[index]);
          const currentSsPermissions =
            !!user?.permissions && user?.permissions.hasOwnProperty(activeStudioSite.studioSiteId)
              ? user.permissions[activeStudioSite.studioSiteId]
              : null;
          const newSsPermissions =
            !!permissions && permissions.hasOwnProperty(activeStudioSite.studioSiteId)
              ? permissions[activeStudioSite.studioSiteId]
              : null;
          const isCurrentPermissionChanged =
            activeStudioSite.studioSiteId > 0 && !areObjectsEqual(currentSsPermissions, newSsPermissions);

          // If set of studiosites is changed - re-set permissions.
          if (isPermissionsLengthDiffer || isPermissionsElementsDiffer || isCurrentPermissionChanged) {
            this.store.dispatch(
              AuthActions.loginSuccess({
                user: { ...user!, permissions, userType },
                isSimpleRefresh: true,
              })
            );
          }

          if (isCurrentPermissionChanged) {
            localStorage.removeItem('returnUrl');
            localStorage.removeItem('startUrl');
            this.dialog.closeAll();
            this.store.dispatch(AuthActions.navigateToAvailableStudioSite({ withPageReload: true }));
          }

          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  loadUserInfoOnInitialize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loadUserInfoOnInitialize),
      withLatestFrom(this.store.pipe(select(getUserToken))),
      switchMap(([_, token]) => {
        if (!token) {
          return EMPTY;
        }
        return of(AuthActions.loadUserInfo({ token, isSimpleRefresh: true }));
      })
    )
  );
}
