import { first, map, mergeMap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { getUser } from '@auth/store/auth.selectors';
import { AppConfigService } from '@shared/services/app-config.service';

@Injectable()
export class CoreHttpInterceptor implements HttpInterceptor {
  constructor(private store: Store, private appConfigService: AppConfigService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.addToken(req).pipe(
      first(),
      mergeMap((requestWithToken: HttpRequest<any>) =>
        next.handle(requestWithToken).pipe(
          map((res: any) =>
            // required until all requests are moved to this format
            req.headers.has('Json-Format') ? res : this.processWidgetResponse(req, res)
          )
        )
      )
    );
  }

  private processWidgetResponse(req: any, res: any): any {
    if (
      (req.method === 'POST' || req.method === 'PATCH' || req.method === 'PUT' || req.method === 'DELETE') &&
      res &&
      res.ok &&
      res.body &&
      res.url &&
      /widgets.*data/i.test(res.url) &&
      res.body.meta.widget
    ) {
      const bindings = res.body.meta.widget.data_configs.bindings;
      // TODO After API team will finish reworking responses to normal json object - check if this is still needed
      if (res.body.data.rows) {
        res.body.data.rows = res.body.data.rows.map((row: any) => {
          const item: any = {};
          Object.getOwnPropertyNames(bindings).forEach(
            (key) => (item[bindings[key].id] = row.values[bindings[key].params?.dest_index])
          );
          return item;
        });
      } else {
        Object.values(res.body.data).forEach((data: any) => {
          if (!data?.rows?.length) {
            return;
          }

          data.rows = data.rows.map((row: any) => {
            const item: any = {};
            Object.getOwnPropertyNames(bindings).forEach(
              (key) => (item[bindings[key].id] = row.values[bindings[key].params.dest_index])
            );
            return item;
          });
        });
      }
    }
    return res;
  }

  private addToken(request: HttpRequest<any>): Observable<HttpRequest<any>> {
    let customHeaders: any;

    return this.store.pipe(
      select(getUser),
      first(),
      mergeMap((user) => {
        if (user && user.token) {
          if (this.appConfigService.configs && this.appConfigService.configs['CUSTOM_HEADERS']) {
            customHeaders = this.appConfigService.configs['CUSTOM_HEADERS'];
            customHeaders['Token-Type'] = 'Bearer';
            customHeaders['Access-Token'] = user.token;
          }

          request = request.clone({
            setHeaders: customHeaders
              ? customHeaders
              : {
                  'Token-Type': 'Bearer',
                  'Access-Token': user.token,
                },
          });
        }
        return of(request);
      })
    );
  }
}
