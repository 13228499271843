import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { ButtonToggle, CustomButtonToggle } from './models/buttons-toggle.interface';

@Component({
  selector: 'app-button-toggle-group',
  templateUrl: './app-button-toggle-group.component.html',
  styleUrls: ['./app-button-toggle-group.component.scss'],
})
export class AppButtonToggleGroupComponent {
  @Input() buttonToggleFormControl: UntypedFormControl = new UntypedFormControl();
  @Input() customEventButton: string | undefined;
  @Input() customButtonToggle: CustomButtonToggle | undefined;
  @Input() buttonToggleGroup: ButtonToggle[] | undefined;
  @Input() noBorderRadius: string = '';

  @Output() readonly outputCustomButtonEvent = new EventEmitter<any>();
  @Output() readonly outputCustomButtonToggle = new EventEmitter<any>();
  @Output() readonly outputChangeModeEvent = new EventEmitter<{ state: boolean; mode: string }>();
}
