import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import { ToasterService } from '@shared/services/toaster.service';
import { ToastType } from '@shared/modules/components/app-toaster/app-toaster.component';

import * as AuthActions from '../../auth/store/auth.actions';

@Injectable()
export class CoreHttpErrorInterceptor implements HttpInterceptor {
  constructor(private toasterService: ToasterService, private store: Store) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        // TODO: Temporary solution, should be removed in scope of SC-7698
        // TODO Remove condition booking_form_info_all_bookings_widget after setActiveStudioSite refactoring

        if (error.error.meta || error.error.status) {
          if (
            error.error?.meta?.code !== 403 &&
            !(error.status === 500 && error.url.includes('booking_form_info_all_bookings_widget'))
          ) {
            this.toasterService.showWithContent(
              ToastType.ERROR,
              error.error.meta?.user_message || error.error.status?.user_message,
              error.error.meta?.user_instructions || error.error.status?.user_instructions
            );
          }
        }

        if (!window.navigator.onLine) {
          return throwError(error);
        }
        if (error.status === 417 || error.status === 401) {
          this.store.dispatch(
            AuthActions.logout({
              withRequest: false,
              source: 'httpErrorInterceptor',
              message: JSON.stringify(error.error.meta) || JSON.stringify(error.error.status),
            })
          );
        }
        return throwError(error);
      })
    );
  }
}
