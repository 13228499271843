import { Directive, EventEmitter, Input, Output } from '@angular/core';

@Directive({
  selector: 'img[src][defaultImage]',
  host: {
    '(error)': 'onImageError()',
    '[src]': 'imageSrc',
    '[class.broken-image]': 'brokenImage',
  },
})
export class DefaultImageDirective {
  @Input() set src(url: string) {
    if (this.defaultImage && !url) {
      this.imageSrc = this.defaultImage;
      return;
    }

    this.imageSrc = url;
  }

  @Input() defaultImage: string | null;

  @Output() readonly imageBroken: EventEmitter<void> = new EventEmitter<void>();

  public imageSrc: string;
  public brokenImage: boolean;

  public onImageError() {
    const isDefaultApplied = this.defaultImage && this.imageSrc === this.defaultImage;
    this.brokenImage = true;

    if (!this.defaultImage || isDefaultApplied) {
      this.imageBroken.next();
      return;
    }

    this.imageSrc = this.defaultImage;
  }
}
