export enum ContactTypeEnum {
  Artist = 1,
  QueryContact = 2,
  Producer = 3,
  Engineer = 4,
  Assistant = 5,
  ClientContact = 6,
  ARApprover = 7,
  ARContact = 8,
}
