export abstract class Throw {
  public static ifNullOrUndefined<T>(
    value: T,
    message: string
  ): asserts value is Exclude<T, undefined | null> {
    this.ifNull(value, message);
    this.ifUndefined(value, message);
  }

  public static ifUndefined<T>(value: T, message: string): asserts value is Exclude<T, undefined> {
    if (value === undefined) {
      throw new Error(message);
    }
  }

  public static ifNull<T>(value: T, message: string): asserts value is Exclude<T, null> {
    if (value === null) {
      throw new Error(message);
    }
  }

  public static if<T>(value: T, message: string) {
    if (value) {
      throw new Error(message);
    }
  }
}
