import { IStudioSite } from '@shared/models/auth/studiosite.interface';
import { CurrenciesCodeEnum, LocaleCodeEnum } from '@shared/models/auth/currencies-code.enum';
import { PrimaryBookingAsset } from '../general-booking-settings';

export function mapLocal(locale: string): string {
  switch (locale) {
    case CurrenciesCodeEnum.US:
      return LocaleCodeEnum.US;
    case CurrenciesCodeEnum.Germany:
      return LocaleCodeEnum.Germany;
    case CurrenciesCodeEnum.Italy:
      return LocaleCodeEnum.Italy;
    case CurrenciesCodeEnum.Austria:
      return LocaleCodeEnum.Austria;
    case CurrenciesCodeEnum.France:
      return LocaleCodeEnum.France;
    case CurrenciesCodeEnum.Spain:
      return LocaleCodeEnum.Spain;
    case CurrenciesCodeEnum.GreatBritain:
      return LocaleCodeEnum.GreatBritain;
    case CurrenciesCodeEnum.Mexico:
      return LocaleCodeEnum.Mexico;
    case CurrenciesCodeEnum.Sweden:
      return LocaleCodeEnum.Sweden;
    case CurrenciesCodeEnum.Australia:
      return LocaleCodeEnum.Australia;
    case CurrenciesCodeEnum.Korea:
      return LocaleCodeEnum.Korea;
    case CurrenciesCodeEnum.Norway:
      return LocaleCodeEnum.Norway;
    case CurrenciesCodeEnum.SouthAfrica:
      return LocaleCodeEnum.SouthAfrica;
    case CurrenciesCodeEnum.Poland:
      return LocaleCodeEnum.Poland;
    case CurrenciesCodeEnum.Portugal:
      return LocaleCodeEnum.Portugal;

    default:
      return LocaleCodeEnum.US;
  }
}

export class StudioSite {
  public organization: number;
  public studioSiteId: number;
  public studioSiteName: string;
  public studioSiteSlug: string;
  public studioSiteShortName: string;
  public timezone: string;
  public currencyCode: any;
  public iconLink: string;
  public dateFormat: string;
  public is24hTimeFormat: boolean;
  public roleName: string;
  public cancelTableChangesByClickOutside: boolean = false;
  public miniVersion: boolean;
  public invoicingNotRequired: boolean;
  public allowRatesForMiniVersion: boolean;
  public locale?: string;
  public thirdPartyStudio: boolean;
  public cmsAccessOnly: boolean;
  public primaryBookingAsset: PrimaryBookingAsset | '';

  constructor(raw?: IStudioSite) {
    if (!raw) {
      return;
    }

    this.organization = raw.organization;
    this.studioSiteId = raw.studiosite;
    this.studioSiteName = raw.studiosite_name;
    this.studioSiteSlug = raw.studiosite_slug;
    this.studioSiteShortName = raw.studiosite_short_name;
    this.timezone = raw.timezone;
    this.currencyCode = raw.currency_code;
    this.iconLink = raw.icon_link;
    this.dateFormat = raw.date_format;
    this.is24hTimeFormat = !raw.date_format?.toLowerCase().startsWith('mm/dd') || !raw.date_format || false;
    this.roleName = raw.role_name;
    this.miniVersion = raw.mini_version ?? false;
    this.invoicingNotRequired = raw.invoicing_not_required ?? false;
    this.allowRatesForMiniVersion = raw.allow_rates_for_mini_version ?? false;
    this.thirdPartyStudio = raw.third_party_studio ?? false;
    this.cmsAccessOnly = raw.cms_access_only ?? false;
    this.primaryBookingAsset = raw.primary_booking_asset ?? '';
    // ------------------------------------------------------------------------------------------
    // SC-12131
    // cancel_table_changes_by_click_outside - this is an arbitrary flag that will be replaced in the future when the API adds its own flag
    // and the integration of the "on/off click outside" functionality is done
    this.cancelTableChangesByClickOutside = raw.cancel_table_changes_by_click_outside ?? false;
    // ------------------------------------------------------------------------------------------
    this.locale = mapLocal(raw.locale);
  }
}
