import { Injectable } from '@angular/core';
import { AuthState } from 'src/app/auth/store/auth.reducer';
import { select, Store } from '@ngrx/store';
import { getUserToken } from 'src/app/auth/store/auth.selectors';

@Injectable()
export class ImageTokenService {
  private lastUpdatedToken: string | null;

  constructor(private store: Store<AuthState>) {
    this.store.pipe(select(getUserToken)).subscribe((newToken: string | null) => {
      this.lastUpdatedToken = newToken;
    });
  }

  public getLastUpdatedToken(): string | null {
    return this.lastUpdatedToken;
  }
}
