import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-user-photo',
  templateUrl: './app-user-photo.component.html',
  styleUrls: ['./app-user-photo.component.scss'],
})
export class AppUserPhotoComponent implements OnInit, OnChanges {
  @Input() classes = '';
  @Input() user: any;

  public userInitials: string = '';

  ngOnInit(): void {
    this.getUserInitials();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getUserInitials();
  }

  private getUserInitials(): void {
    let name: string = '';

    if (this.user?.name) {
      name = this.user.name;
    }

    if (this.user?.firstName) {
      name = `${this.user.firstName} `;
    }

    if (this.user?.lastName) {
      name += this.user.lastName;
    }

    this.userInitials = name
      .split(' ')
      .map((n: string) => n[0]?.toUpperCase())
      .join('')
      .slice(0, 2);
  }
}
