import { createReducer, on } from '@ngrx/store';

import * as CoreActions from './core.actions';
import { environment } from '@env/environment';

export const coreStateFeatureKey = 'core';

export interface CoreState {
  sidenavOpen: boolean;
  previousUrl: string | null;
  fullPagePending: boolean;
  error: any;
}

const initialState: CoreState = {
  sidenavOpen: environment.production,
  previousUrl: null,
  fullPagePending: false,
  error: '',
};

const coreReducers = createReducer(
  initialState,

  on(CoreActions.openSidenav, (state) => ({
    ...state,
    sidenavOpen: true,
  })),
  on(CoreActions.closeSidenav, (state) => ({
    ...state,
    sidenavOpen: false,
  })),
  on(CoreActions.setPreviousUrl, (state, { url }) => ({
    ...state,
    previousUrl: url,
  })),
  on(CoreActions.setFullPagePending, (state, { pending }) => ({
    ...state,
    fullPagePending: pending,
  }))
);

export function reducer(state = initialState, action: any) {
  return coreReducers(state, action);
}
