import { StudiositePermission } from '@shared/models/auth/studiosite-permission.model';
import { StudiositePreference } from '@shared/models/auth/studiosite-preference.model';
import { IUserAuth } from '@shared/models/auth/user-auth.interface';

import { UserTypeEnum } from '@shared/enums/user-type.enum';

import { UserRolesModel } from './user-roles.model';
import { GlobalPreference } from '@shared/models/auth/global-preference.model';
import { UserGlobalPreference } from '@shared/models/auth/user-global-preference.model';

export class UserModel {
  public token: string | null;
  public name: string | null;
  public lifetime: number | null;
  public expireAfter: number | null;
  public actions: string[] | null;
  public settings: {
    allowedExtensions: string[];
  };
  public userProfileId: number | null;
  public preferences: {
    studiositePrefs: { [key: string]: StudiositePreference } | null;
    globalPrefs: GlobalPreference | null;
  } | null;
  public permissions: { [key: string]: StudiositePermission } | null;
  public email: string | null;
  public first_name: string | null;
  public last_name: string | null;
  public userRoles: UserRolesModel[] | null;
  public userType: UserTypeEnum;
  public fullName: string;
  public company: string;
  public defaultRole: string;
  public globalUserPreferences?: UserGlobalPreference;

  constructor(
    raw?: IUserAuth,
    preferences?: {
      studiositePrefs: { [key: string]: StudiositePreference } | null;
      globalPrefs: GlobalPreference | null;
    } | null,
    permissions?: { [key: string]: StudiositePermission },
    userType?: UserTypeEnum
  ) {
    if (!raw) {
      return;
    }

    this.token = raw.token ? raw.token : null;
    this.name = raw.name ? raw.name : null;
    this.lifetime = raw.lifetime ? raw.lifetime : null;
    this.expireAfter = raw.expire_after ? raw.expire_after : null;
    this.actions = raw.actions ? raw.actions : null;
    this.settings = {
      allowedExtensions: raw.settings?.allowed_extensions?.length
        ? raw.settings.allowed_extensions
        : [],
    };
    this.userProfileId = raw.preferences?.user_profile_id ? raw.preferences.user_profile_id : null;
    this.preferences = preferences ?? null;
    this.permissions = permissions || {};
    this.email = raw.email ? raw.email : null;
    this.userType = userType || UserTypeEnum.schedule;
    this.fullName = raw.fullname;
    this.company = raw.company;
    this.defaultRole = raw.role;

    // This values will be used for global user groups
    if (raw.preferences?.global) {
      this.globalUserPreferences = {
        fullName: raw.preferences.global.full_name ?? '',
        company: raw.preferences.global.company ?? '',
        email: raw.preferences.global.email ?? '',
        userProfileId: raw.preferences.global.user_profile_id ?? this.userProfileId,
      };
    }
  }
}
