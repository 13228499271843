import { Injectable } from '@angular/core';
import { LocalStorageEnum } from '@shared/enums/local-storage.enum';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  public getCurrentLocale(): string {
    const authRaw = localStorage.getItem(LocalStorageEnum.Auth);

    let locale;

    if (authRaw) {
      locale = JSON.parse(authRaw)?.activeStudioSite?.locale;
    }

    if (!locale?.length) {
      locale = 'en';
    }

    return locale;
  }
}
