import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { GlobalPrefsEnum } from '@shared/enums/global-prefs.enum';
import { RoutesEnum } from '@shared/enums/routes.enum';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { UserModel } from '@shared/models/auth/user.model';
import { getUser } from '@auth/store/auth.selectors';
import { GlobalPreference } from '@shared/models/auth/global-preference.model';

@Injectable()
export class HomePermissionGuard {
  constructor(private store: Store<any>, private router: Router) {}

  readonly pathList: { path: string; permission: string }[] = [
    { path: RoutesEnum.homeSettings, permission: GlobalPrefsEnum.globalSettings },
    { path: RoutesEnum.settingsUsers, permission: GlobalPrefsEnum.globalAdminUsers },
    {
      path: RoutesEnum.homeSettingsBookingCategories,
      permission: GlobalPrefsEnum.globalSettingsTabBookingCategories,
    },
  ];

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const path: string = state.url;
    return this.store.pipe(
      select(getUser),
      map((user: UserModel | null) => {
        if (!user || !user.preferences || !user.permissions) {
          this.router.navigate(['/studios']);
          return false;
        }

        const res = this.pathList.some((item) =>
          path.startsWith(item.path) && !!user.preferences?.globalPrefs
            ? user.preferences.globalPrefs[item.permission as keyof GlobalPreference]
            : false
        );

        if (res) {
          return true;
        }

        this.router.navigate(['/studios']);
        return false;
      }),
      take(1)
    );
  }
}
