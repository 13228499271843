import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { AppButtonLabelTemplateDirective } from '@shared/modules/components/app-button/app-button-label-template.directive';

@Component({
  selector: 'app-button',
  templateUrl: './app-button.component.html',
  styleUrls: ['./app-button.component.scss'],
})
export class AppButtonComponent {
  @Input() matIconName: string;
  @Input() img: {
    url: string;
    height?: string;
    width?: string;
  };
  @Input() color:
    | 'thunder'
    | 'primary'
    | 'secondary'
    | 'inline'
    | 'inline-red'
    | 'catskill'
    | 'red'
    | 'scarlet'
    | 'flame-pea'
    | 'black'
    | 'macos-like'
    | 'sap'
    | 'sap-dark'
    | 'stroked-white'
    | 'dashed-white'
    | 'embossing'
    | 'grey' = 'primary';
  @Input() classes = '';
  @Input() disabled = false;
  @Input() fontWeight = '';
  @Input() fontSize = 'fs-16';
  @Input() isDisabledTooltip: boolean = false;
  @Input() label = 'Button';
  @Input() size: 'large' | 'regular' | 'small' | 'xSmall' | 'xxSmall' = 'regular';
  @Input() textTooltip: string = '';
  @Input() type: 'submit' | 'button' | 'reset' = 'submit';
  @Input() pending: boolean;

  @Output() clickEvent = new EventEmitter<Event>();

  @ContentChild(AppButtonLabelTemplateDirective, { read: TemplateRef })
  labelTemplate: TemplateRef<any>;

  public getBtnClasses(): string {
    return `app-button btn btn--${this.color} btn--${this.size} ${this.classes} ${this.fontSize} ${this.fontWeight}`;
  }
}
