import { DateTime } from 'luxon';
import { dateShortYearFormatUI } from '@env/environment';

export function convertRangeOfDates(value: string[], formatDate: string): string {
  if (!value.length) {
    return '';
  }

  const sortableDates = value
    .sort(function (a: string, b: string) {
      return DateTime.fromFormat(a, formatDate).toMillis() - DateTime.fromFormat(b, formatDate).toMillis();
    })
    .map((item) => DateTime.fromFormat(item, formatDate));

  let strRaw: string[] = [];

  let ranges: DateTime[][] = [];
  let indexRange = 0;

  sortableDates.reduce((a, b, index): any => {
    const compare = b.diff(a, ['days']).toObject();
    const itemDate = b;

    if (index === 0) {
      ranges[indexRange] = [a];

      if (compare?.days && compare.days !== 1) {
        indexRange++;
      }
    } else {
      if (compare?.days && compare.days === 1) {
        ranges[indexRange]?.length ? ranges[indexRange].push(itemDate) : (ranges[indexRange] = [itemDate]);
      } else {
        indexRange++;
        ranges[indexRange]?.length ? ranges[indexRange].push(itemDate) : (ranges[indexRange] = [itemDate]);
      }
    }

    return b;
  }, sortableDates[0]);

  ranges.forEach((item, index) => {
    item.forEach((range) => {
      if (formatDate === dateShortYearFormatUI) {
        if (item.length === 1) {
          if (index < ranges.length - 1) {
            const nextRangeMonth = ranges[index + 1] ? ranges[index + 1][0].toFormat('MM') : item[0].toFormat('MM');
            const insideNextDateCurrentMonth = item[0].toFormat('MM') === nextRangeMonth;

            strRaw.push(`${range.toFormat(`MM/dd${insideNextDateCurrentMonth ? '' : '/yy'}`)}`);
          } else {
            strRaw.push(`${range.toFormat(formatDate)}`);
          }
        }

        if (item.length > 1) {
          const insideOneMonth = item[0].toFormat('MM') === item[item.length - 1].toFormat('MM');

          strRaw.push(
            `${item[0].toFormat(`MM/dd`)} - ${item[item.length - 1].toFormat('MM/dd')}/${item[item.length - 1].toFormat(
              'yy'
            )}`
          );
        }
      } else {
        if (item.length === 1) {
          if (index < ranges.length - 1) {
            const nextRangeMonth = ranges[index + 1] ? ranges[index + 1][0].toFormat('MM') : item[0].toFormat('MM');
            const insideNextDateCurrentMonth = item[0].toFormat('MM') === nextRangeMonth;

            strRaw.push(
              `${range.toFormat(
                `dd${insideNextDateCurrentMonth ? '' : '/MM'}${insideNextDateCurrentMonth ? '' : '/yy'}`
              )}`
            );
          } else {
            strRaw.push(`${range.toFormat(formatDate)}`);
          }
        }

        if (item.length > 1) {
          const insideOneMonth = item[0].toFormat('MM') === item[item.length - 1].toFormat('MM');

          strRaw.push(
            `${item[0].toFormat(`dd${insideOneMonth ? '' : '/MM'}`)} - ${item[item.length - 1].toFormat(
              'dd/MM'
            )}/${item[item.length - 1].toFormat('yy')}`
          );
        }
      }
    });
  });

  strRaw = [...new Set(strRaw)];

  return strRaw.join(', ');
}
