import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import * as DataManagementHeaderActions from '@shared/modules/app-data-management-header/store/app-data-management-header.actions';
import * as ContactsPersonActions from '@contacts/contacts-person/store/contacts-person.actions';
import * as ContactsCompanyActions from '@contacts/contacts-company/store/contacts-company.actions';
import * as ContactsArtistActions from '@contacts/contacts-artist/store/contacts-artist.actions';
import { ToasterService } from '@shared/services/toaster.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CoreEffects {
  constructor(
    private actions$: Actions,
    private translateService: TranslateService,
    private toasterService: ToasterService
  ) {}

  showNotificationOnSuccessCreation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DataManagementHeaderActions.addNewPersonToBookingContactSuccess,
          ContactsPersonActions.createContactsPersonRequestSuccess,
          ContactsCompanyActions.createContactsCompanyRequestSuccess,
          ContactsArtistActions.createContactsArtistRequestSuccess
        ),
        tap(() =>
          this.toasterService.success(
            this.translateService.instant('SHARED_MESSAGES.CREATE_SUCCESS')
          )
        )
      ),
    { dispatch: false }
  );
}
