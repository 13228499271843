import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { IApiDataResponse } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  public configs: any = null;
  public version = 'Unknown';
  public versionBuild = 'Unknown';

  readonly OAUTH2_MODE = 'oauth2';

  private defaults = {
    oauth2_ping_url: `${environment.BASE_PATH}/v1/me`,
  };

  constructor(private http: HttpClient) {}

  public loadConfigs(): any {
    return this.http.get('./configs.json').pipe(
      catchError((error: any): any => {
        throw error;
      }),
      map(async (configs: any) => {
        environment.BASE_PATH = configs.api_url || '';
        this.defaults.oauth2_ping_url = `${configs.api_url}/${this.defaults.oauth2_ping_url}`;
        this.configs = Object.assign({}, this.defaults, configs);
      })
    );
  }

  public loadVersion(): any {
    if (!environment.production) {
      // return;
    }
    this.http
      .get('./version.json')
      .pipe(
        catchError((error: any): any => {
          throw error;
        })
      )
      .subscribe(({ version, build }: any) => {
        this.version = version;
        this.versionBuild = build;
      });
  }

  public loadVersionFromApi(): Observable<IApiDataResponse<{ app_version: string; build?: string }>> {
    return this.http.get<IApiDataResponse<{ app_version: string; build?: string }>>(
      `${environment.BASE_PATH}/v1/app_version`
    );
  }

  get isUSMAuth(): boolean {
    return this.configs.auth_mode === this.OAUTH2_MODE;
  }

  get pageTitle(): string {
    return this.configs.page_title;
  }
}
