export enum RoutesEnum {
  homeDirectory = '/studios',
  homeSettings = '/settings',
  homeSettingsBookingCategories = '/settings/booking-categories',
  bookings = '/bookings',
  schedule = '/schedule',
  sessionSheets = '/session-sheets',
  invoices = '/invoices',
  invoicesTemp = '/invoices-temp',
  purchaseOrders = '/purchase-orders',
  contacts = '/contacts',
  contactsPerson = '/contacts/person',
  contactsCompany = '/contacts/company',
  contactsArtist = '/contacts/artist',
  contactsAdcontact = '/contacts/adcontact',
  settings = '/settings',
  settingsStudioSite = '/settings/studio-sites',
  settingsBooking = '/settings/bookings',
  settingsUsers = '/settings/users',
  settingsEngineers = '/settings/engineers',
  settingsStaffRates = '/settings/staff-rates',
  settingsSessionSheets = '/settings/session-sheets',
  settingsInvoicing = '/settings/invoicing',
  settingsNotifications = '/settings/notifications',
  settingsStudios = '/settings/studios',
  settingsSchedule = '/settings/schedule',
  settingsAccounting = '/settings/accounting',
  settingsWebsite = '/settings/website',
  reports = '/reports',
}
