import { Injectable } from '@angular/core';

import { FEATURE_TOGGLES } from './feature-toggles.config';
import { FeatureToggleName } from './feature-toggle.interface';

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  private toggles: Record<string, boolean>;

  constructor() {
    this.toggles = FEATURE_TOGGLES.reduce(
      (acc, feature) => ({
        ...acc,
        [feature.name]: feature.defaultValue,
      }),
      {}
    );
  }

  public isEnabled(name: FeatureToggleName): boolean {
    return this.toggles[name];
  }
}
