import { PermissionsEnum } from '@shared/enums/permissions.enum';

import { IStudiositePermission } from './studiosite-permission.interface';
import { StudiositePermissionType } from './studiosite-permission.type';

export class StudiositePermission implements StudiositePermissionType {
  public readonly [PermissionsEnum.isAr]: boolean;
  public readonly [PermissionsEnum.isScheduleUser]: boolean;
  public readonly [PermissionsEnum.menuBooking]: boolean;
  public readonly [PermissionsEnum.menuSchedule]: boolean;
  public readonly [PermissionsEnum.menuSessionSheets]: boolean;
  public readonly [PermissionsEnum.menuInvoices]: boolean;
  public readonly [PermissionsEnum.menuInvoicesTemp]: boolean;
  public readonly [PermissionsEnum.menuPurchaseOrders]: boolean;
  public readonly [PermissionsEnum.menuContacts]: boolean;
  public readonly [PermissionsEnum.menuSettings]: boolean;
  public readonly [PermissionsEnum.menuReports]: boolean;
  public readonly [PermissionsEnum.settingsTabStudioSite]: boolean;
  public readonly [PermissionsEnum.settingsTabStudioSiteMiniConsoleStudio]: boolean;
  public readonly [PermissionsEnum.settingsTabCmsAccessOnly]: boolean;
  public readonly [PermissionsEnum.settingsTabInvoicingNotRequired]: boolean;
  public readonly [PermissionsEnum.settingsTabBookings]: boolean;
  public readonly [PermissionsEnum.settingsTabUsers]: boolean;
  public readonly [PermissionsEnum.settingsTabEngineers]: boolean;
  public readonly [PermissionsEnum.settingsTabUsersScUsers]: boolean;
  public readonly [PermissionsEnum.settingsTabUsersArUsers]: boolean;
  public readonly [PermissionsEnum.settingsTabUsersUserGroups]: boolean;
  public readonly [PermissionsEnum.settingsTabSessionSheets]: boolean;
  public readonly [PermissionsEnum.settingsTabInvoicing]: boolean;
  public readonly [PermissionsEnum.settingsTabNotifications]: boolean;
  public readonly [PermissionsEnum.settingsTabInvoicingThirdPartyBilling]: boolean;
  public readonly [PermissionsEnum.settingsTabStudios]: boolean;
  public readonly [PermissionsEnum.settingsTabSchedule]: boolean;
  public readonly [PermissionsEnum.settingsTabAccounting]: boolean;
  public readonly [PermissionsEnum.settingsTabCreateStudio]: boolean;
  public readonly [PermissionsEnum.settingsTabWebsiteAndDirectory]: boolean;
  public readonly [PermissionsEnum.bookings]: boolean;
  public readonly [PermissionsEnum.bookingView]: boolean;
  public readonly [PermissionsEnum.schedule]: boolean;
  public readonly [PermissionsEnum.scheduleCreateBooking]: boolean;
  public readonly [PermissionsEnum.scheduleCreateNonBooking]: boolean;
  public readonly [PermissionsEnum.scheduleCreateMeeting]: boolean;
  public readonly [PermissionsEnum.scheduleGeneralCalendarView]: boolean;
  public readonly [PermissionsEnum.sessionSheets]: boolean;
  public readonly [PermissionsEnum.contacts]: boolean;
  public readonly [PermissionsEnum.contactsAddressBook]: boolean;
  public readonly [PermissionsEnum.contactsSonyContacts]: boolean;
  public readonly [PermissionsEnum.contactsSonyCompanies]: boolean;
  public readonly [PermissionsEnum.scheduleAllowCancelledEvents]: boolean;
  public readonly [PermissionsEnum.settingsUsersScheduleUsersAddNew]: boolean;
  public readonly [PermissionsEnum.settingsUsersScheduleUsersDefaultsViewOnly]: boolean;
  public readonly [PermissionsEnum.settingsTabThirdPartyStudio]: boolean;
  public readonly [PermissionsEnum.bookingsDeletedTab]: boolean;
  public readonly [PermissionsEnum.isArBooker]: boolean;

  constructor(raw?: IStudiositePermission) {
    if (!raw) {
      return;
    }

    this[PermissionsEnum.isAr] = raw.is_ar ?? false;
    this[PermissionsEnum.isScheduleUser] = raw.is_schedule ?? false;
    this[PermissionsEnum.menuBooking] = raw.menu_booking ?? false;
    this[PermissionsEnum.menuSchedule] = raw.menu_schedule ?? false;
    this[PermissionsEnum.menuSessionSheets] = raw.menu_session_sheets ?? false;
    this[PermissionsEnum.menuInvoices] = raw.menu_invoices ?? false;
    this[PermissionsEnum.menuPurchaseOrders] = raw.menu_purchase_orders ?? false;
    this[PermissionsEnum.menuContacts] = raw.menu_contacts ?? false;
    this[PermissionsEnum.menuSettings] = raw.menu_settings ?? false;
    this[PermissionsEnum.menuReports] = raw.menu_reports ?? false;
    this[PermissionsEnum.settingsTabStudioSite] = raw.settings_tab_studio_site ?? false;
    this[PermissionsEnum.settingsTabStudioSiteMiniConsoleStudio] =
      raw.settings_tab_studio_site_mini_console_studio ?? false;
    this[PermissionsEnum.settingsTabInvoicingNotRequired] = raw.settings_tab_invoicing_not_required ?? false;
    this[PermissionsEnum.settingsTabBookings] = raw.settings_tab_bookings ?? false;
    this[PermissionsEnum.settingsTabUsers] = raw.settings_tab_users ?? false;
    this[PermissionsEnum.settingsTabEngineers] = raw.settings_tab_engineers ?? false;
    this[PermissionsEnum.settingsTabUsersScUsers] = raw.settings_tab_users_sc_users ?? false;
    this[PermissionsEnum.settingsTabUsersArUsers] = raw.settings_tab_users_ar_users ?? false;
    this[PermissionsEnum.settingsTabUsersUserGroups] = raw.settings_tab_users_user_groups ?? false;
    this[PermissionsEnum.settingsTabSessionSheets] = raw.settings_tab_session_sheets ?? false;
    this[PermissionsEnum.settingsTabInvoicing] = raw.settings_tab_invoicing ?? false;
    this[PermissionsEnum.settingsTabNotifications] = raw.settings_tab_notifications ?? false;
    this[PermissionsEnum.settingsTabInvoicingThirdPartyBilling] =
      raw.settings_tab_invoicing_third_party_billing ?? false;
    this[PermissionsEnum.bookings] = raw.bookings ?? false;
    this[PermissionsEnum.bookingView] = raw.booking_view ?? false;
    this[PermissionsEnum.schedule] = raw.schedule ?? false;
    this[PermissionsEnum.scheduleCreateBooking] = raw.schedule_create_booking ?? false;
    this[PermissionsEnum.scheduleCreateNonBooking] = raw.schedule_create_non_booking ?? false;
    this[PermissionsEnum.scheduleCreateMeeting] = raw.schedule_create_meeting ?? false;
    this[PermissionsEnum.scheduleGeneralCalendarView] = raw.schedule_general_calendar_view ?? false;
    this[PermissionsEnum.sessionSheets] = raw.session_sheets ?? false;
    this[PermissionsEnum.contacts] = raw.contacts ?? false;
    this[PermissionsEnum.contactsAddressBook] = raw.contacts_address_book ?? false;
    this[PermissionsEnum.contactsSonyContacts] = raw.contacts_sony_contacts ?? false;
    this[PermissionsEnum.contactsSonyCompanies] = raw.contacts_sony_companies ?? false;
    this[PermissionsEnum.settingsTabStudios] = raw.settings_tab_studios ?? false;
    this[PermissionsEnum.settingsTabSchedule] = raw.settings_tab_schedule ?? false;
    this[PermissionsEnum.settingsUsersScheduleUsersAddNew] = raw.settings_users_schedule_users_add_new ?? false;
    this[PermissionsEnum.settingsUsersScheduleUsersDefaultsViewOnly] =
      raw.settings_users_schedule_users_defaults_view_only ?? false;
    this[PermissionsEnum.scheduleAllowCancelledEvents] = raw.schedule_allow_cancelled_events ?? false;

    this[PermissionsEnum.menuInvoicesTemp] = raw.menu_invoices ?? false;
    this[PermissionsEnum.bookingsDeletedTab] = raw.bookings_deleted_tab ?? false;
    this[PermissionsEnum.isArBooker] = raw.is_ar_booker ?? false;
    this[PermissionsEnum.settingsTabAccounting] = raw.settings_tab_accounting ?? false;
    this[PermissionsEnum.settingsTabCreateStudio] = raw.settings_tab_create_studio ?? false;
    this[PermissionsEnum.settingsTabThirdPartyStudio] = raw.settings_tab_third_party_studio ?? false;
    this[PermissionsEnum.settingsTabWebsiteAndDirectory] = raw.settings_tab_website_and_directory ?? false;
    this[PermissionsEnum.settingsTabCmsAccessOnly] = raw.settings_tab_cms_access_only ?? false;
  }
}
