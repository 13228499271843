import { cloneDeep, isObject, isEqual } from '@shared/utils';

export function difference(obj: any, baseObj: any, skipSingleType = false): any {
  // cloneDeep - needed to ensure that the checked objects are cast to a single type.
  // Because the "isEqual" method also checks the prototype and object constructor
  // -----------------------------------------
  // EXAMPLE:
  // - const blah1 = { 'a': 1 };
  // - const blah2 = new Blah({ 'a': 1 });
  // - isEqual(blah1, blah2) -> result false
  if (!skipSingleType) {
    obj = cloneDeep(obj);
    baseObj = cloneDeep(baseObj);
  }
  // -----------------------------------------
  function changes(object: any, base: any): any {
    return Object.entries(object).reduce((acc, [key, value]) => {
      if (!isEqual(value, base[key])) {
        acc[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
      }
      return acc;
    }, {} as Record<string, any>);
  }
  return changes(obj || {}, baseObj || {});
}

export function hasDifferences(obj1: any, obj2: any, ignoreFields: string[] = []): boolean {
  const cleanObject = (obj: any) =>
    Object.keys(obj)
      .filter((k) => !ignoreFields.includes(k))
      .filter((k) => obj[k] != null && obj[k] !== '')
      .reduce((acc: any, k: any) => {
        acc[k] = obj[k];
        return acc;
      }, {});
  return JSON.stringify(cleanObject(obj1)) !== JSON.stringify(cleanObject(obj2));
}

export function areObjectsEqual(obj1: any, obj2: any): boolean {
  if (obj1 === obj2) {
    return true;
  }

  if (obj1 instanceof Date && obj2 instanceof Date) {
    return obj1.getTime() === obj2.getTime();
  }

  if (!obj1 || !obj2 || (typeof obj1 !== 'object' && typeof obj2 !== 'object')) {
    return obj1 === obj2;
  }

  if (obj1.prototype !== obj2.prototype) {
    return false;
  }

  const keys = Object.keys(obj1);
  if (keys.length !== Object.keys(obj2).length) {
    return false;
  }

  return keys.every((k) => areObjectsEqual(obj1[k], obj2[k]));
}
