export enum BookingStatusFilterEnum {
  ALL = 'all',
  NEW_QUERIES = 'new-queries',
  OPEN_QUERIES = 'open-queries',
  HOLDS = 'holds',
  CONFIRMATIONS_SENT = 'confirmations-sent',
  CONFIRMED = 'confirmed',
  TO_BE_INVOICED = 'to-be-invoiced',
  EVENTS_TO_CHECK = 'events-to-check',
  ARCHIVED = 'archived',
  DELETED = 'deleted',
}
