import { Injectable } from '@angular/core';

export const localStorageKey = 'closedSchedulerBanners';

@Injectable({
  providedIn: 'root',
})
export class SchedulerBannerStorageService {
  public setClosedScheduleBanner(studioSiteId: number): void {
    const storageData = this.getFromLocalStorage();

    localStorage.setItem(
      localStorageKey,
      JSON.stringify(Array.isArray(storageData) ? storageData.concat(studioSiteId) : [studioSiteId])
    );
  }

  public isClosedScheduleBanner(studioSiteId: number): boolean {
    const storageData = this.getFromLocalStorage();

    if (!storageData) {
      return false;
    }

    return storageData.includes(studioSiteId);
  }

  public clear() {
    localStorage.removeItem(localStorageKey);
  }

  private getFromLocalStorage(): number[] | null {
    const storageData: string | null = localStorage.getItem(localStorageKey);
    if (!storageData) {
      return null;
    }

    try {
      return JSON.parse(storageData);
    } catch {
      return null;
    }
  }
}
